import React from 'react';
import { createSvgIcon } from '@mui/material';

const NineSvg = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
    <defs>
      <clipPath id="clip-Artboard_9">
        <rect width="28" height="28" />
      </clipPath>
    </defs>
    <g id="Artboard_9" data-name="Artboard – 9" clipPath="url(#clip-Artboard_9)">
      <text id="_9" data-name="9" transform="translate(10 19)" fill="currentColor" fontSize="14" fontFamily="Poppins-Regular, Poppins" fontWeight="bold"><tspan x="0" y="0">9</tspan></text>
    </g>
  </svg>
);

export default NineSvg;
