import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogContent, DialogActions, Button, Box, IconButton, Typography
} from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { dismissPopup, sawUpdatePopup } from '../../../actions/userUpdates/updateUser';
import PageOne from './PageOne';
import SofieComp from '../../../assets/svgs/SofieChatNew.png';

const UpdateModal = (props) => {
  const { open, dispatchSawUpdatePopup, dispatchDismissPopup } = props;

  const [page, setPage] = useState(1);

  /* <PageTwo key="2" />, */

  const content = [
    <PageOne key="1" />
  ];

  const handleClose = (e, reason, save) => {
    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
      if (save) {
        dispatchSawUpdatePopup();
      } else {
        dispatchDismissPopup();
      }
    }
  };

  const handleSecondary = () => {
    if (page === 1) {
      handleClose({}, '', false);
    } else {
      setPage(page - 1);
    }
  };

  const handlePrimary = () => {
    if (page === content.length) {
      handleClose({}, '', true);
    } else {
      setPage(page + 1);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={(e, r) => {
        handleClose(e, r, true);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: '16px',
          height: { xs: 'auto', sm: '600px' },
          overflowX: 'hidden',
          minWidth: { xs: 'auto', sm: '600px' },
        },
      }}
    >
      <Box
        id="alert-dialog-title"
        sx={{
          paddingBottom: 0,
          paddingTop: '32px',
          paddingLeft: { xs: 2, sm: 6.5 },
          paddingRight: '16px',
          textAlign: 'center',
          backgroundColor: '#f2f3f4',
          minHeight: { xs: 'auto', sm: '255px' },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'flex-start', sm: 'center' },
            height: '100%',
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: '2rem', sm: '2.5rem' },
              color: 'lightBlue.main',
              maxWidth: { xs: 'auto', sm: '240px' },
              textAlign: { xs: 'center', sm: 'left' },
              fontWeight: 'bold',
            }}
          >
            Welcome to Sofie AI
          </Typography>
          <Box
            sx={{
              width: { xs: 'auto', sm: '600px' },
              position: { xs: 'relative', sm: 'absolute' },
              top: { xs: 0, sm: '-12px' },
              right: { xs: 0, sm: '-223px' },
              maxHeight: '310px',
            }}
          >
            <img
              src={SofieComp}
              alt="Sofie new user interface update"
              style={{
                maxWidth: '100%',
                maxHeight: '310px',
                position: { xs: 'relative', sm: 'relative' }
              }}
            />
          </Box>
        </Box>
        {/* <IconButton
          onClick={() => {
            handleClose({}, '', true);
          }}
          sx={{
            position: 'absolute',
            top: { xs: '5px', md: '15px' },
            right: '15px',
          }}
        >
          <CancelRoundedIcon
            sx={{ fontSize: { xs: '1.5rem', sm: '2.13rem' }, zIndex: 100, color: '#0A1C2A' }}
          />
          <Box
            component="span"
            sx={{
              position: 'absolute',
              width: '14px',
              height: '14px',
              backgroundColor: 'white.main',
            }}
          />
        </IconButton> */}
      </Box>
      <DialogContent
        sx={{
          padding: 0,
        }}
      >
        <Box
          pl={6.625}
          pr={3.125}
          py={{ xs: 2, md: 0 }}
          id="alert-dialog-description"
          width="100%"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          {content[page - 1]}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-between',
          pb: 2,
          pl: 6.625,
          pr: 3.125,
          pt: 0,
        }}
      >
        <Typography>{content.length > 1 && `${page}/${content.length}`}</Typography>
        <Box>
          {content.length > 1
          && (
          <Button
            onClick={handleSecondary}
            color="secondary"
            sx={{
              padding: { xs: '5px 20px', sm: '10px 40px' },
              fontSize: '1rem',
              borderRadius: '8px',
            }}
            autoFocus
          >
            {page === 1 ? 'Skip' : 'Back'}
          </Button>
          )}
          <Button
            onClick={handlePrimary}
            variant="contained"
            color="secondary"
            sx={{
              padding: { xs: '5px 20px', sm: '10px 40px' },
              fontSize: '1rem',
              borderRadius: '8px',
            }}
            autoFocus
          >
            {page === content.length ? 'Done' : 'Next'}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{logout: *, authToken: *, login: *}} - bound action creators
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({
  dispatchDismissPopup: dismissPopup,
  dispatchSawUpdatePopup: sawUpdatePopup,
},
dispatch);

UpdateModal.propTypes = {
  open: PropTypes.bool.isRequired,
  dispatchDismissPopup: PropTypes.func.isRequired,
  dispatchSawUpdatePopup: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(UpdateModal);
