import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@mui/material';


const BookIcon = (props) => {
  const {
    styleObject
  } = props;
  return (
    <SvgIcon
      viewBox="0 0 448 512"
      sx={styleObject}
    >
      <path
        d="M448 352V48C448 21.53 426.5 0 400 0h-320C35.89 0 0 35.88 0 80v352C0 476.1 35.89 512 80 512h344c13.25 0 24-10.75 24-24s-10.75-24-24-24H416v-66.95C434.6 390.4 448 372.8 448 352zM368 464h-288c-17.64 0-32-14.34-32-32s14.36-32 32-32h288V464zM400 352h-320c-11.38 0-22.2 2.375-32 6.688V80c0-17.66 14.36-32 32-32h320V352zM152 160h176C341.3 160 352 149.3 352 136S341.3 112 328 112h-176C138.8 112 128 122.8 128 136S138.8 160 152 160zM152 240h176C341.3 240 352 229.3 352 216S341.3 192 328 192h-176C138.8 192 128 202.8 128 216S138.8 240 152 240z"
      />
    </SvgIcon>
  );
};

export default BookIcon;

BookIcon.propTypes = {
  styleObject: PropTypes.shape({
    color: PropTypes.string,
    fontSize: PropTypes.string,
    marginBottom: PropTypes.string
  })
};
BookIcon.defaultProps = {
  styleObject: PropTypes.shape({
    color: '',
    fontSize: '',
    marginBottom: ''
  })
};
