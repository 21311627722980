/* eslint-disable max-len */
import {
  Box, List, ListItem, ListItemText, Typography
} from '@mui/material';
import React from 'react';
import Title from './Title';
import Body from './Body';

const PageOne = () => (
  <Box
    sx={{
      padding: '5%'
    }}
  >
    <Title
      color="lightBlue.main"
      size={1.25}
      marginTop="20%"
    >
      I have new features to enhance your experience! You can now:
    </Title>
    <Box mt={0.75} />
    <List sx={{ listStyleType: 'disc' }}>
      <ListItem
        sx={{ display: 'list-item', p: 0 }}
      >
        <ListItemText>
          <Title color="darkGrey.main">
            Ask Sofie for Differential Diagnoses: Get quick, relevant differential diagnosis suggestions directly in the chat.
          </Title>
        </ListItemText>

      </ListItem>
      <ListItem
        sx={{ display: 'list-item', p: 0 }}
      >
        <Title color="darkGrey.main">
          Integrated Calculators: Need Specific calculations? Just ask me, and access veterinary calculators instantly within the conversation.
        </Title>
      </ListItem>
      <ListItem sx={{ display: 'list-item', p: 0 }}>
        <Title color="darkGrey.main">
          Voice Search: Simply tap and speak to ask questions, making it easier to find answers on the go.

        </Title>
      </ListItem>
      <ListItem sx={{ display: 'list-item', p: 0 }}>
        <Title color="darkGrey.main">
          Favorites and Chat History: Save important information and revisit past conversations and insights whenever needed.
        </Title>
      </ListItem>
    </List>
    {/* <Title color="darkGrey.main">
      I have new features to enhance your experience! You can now:
      Ask Sofie for Differential Diagnoses: Get quick, relevant differential diagnosis suggestions directly in the chat.

      Integrated Calculators: Need Specific calculations? Just ask me, and access veterinary calculators instantly within the conversation.

      Voice Search – Simply tap and speak to ask questions, making it easier to find answers on the go.

      Favorites and Chat History – Save important information and revisit past conversations and insights whenever needed.
    </Title> */}
    {/* <Body size={0.875}>Hovda, Brutlag, Poppenga, Peterson; Wiley-Blackwell 2016</Body>
    <Box mt={0.75} />
    <Title color="darkGrey.main">Carpenters&apos;s Exotic Animal Formulary, 6th edition</Title>
    <Body size={0.875}>Carpenter; 2023</Body>
    <Box mt={0.75} />
    <Title
      color="lightBlue.main"
      size={1.25}
    >
      Updated Content
    </Title>
    <Box mt={0.75} />
    <Title color="darkGrey.main">VMX Conference Proceedings 2023</Title>
    <Box mt={0.75} />
    <Title color="darkGrey.main">Today&apos;s Veterinary Practice Journal 2013 to 2023</Title> */}
  </Box>
);

PageOne.propTypes = {};

export default PageOne;
