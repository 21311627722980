/* eslint-disable brace-style */
/* eslint-disable max-len */
// eslint-disable-next-line import/no-extraneous-dependencies
import { v4 as uuidv4 } from 'uuid';

/* eslint-disable no-useless-escape */
/* eslint-disable no-continue */
/**
 * some desc
 * @param {string} chatScriptQueryString - desc
 * @param {HTMLScriptElement} myScript - script
 * @returns {ParamsObject} - paramter object
 */
const parseJsScriptQuery = (
  chatScriptQueryString,
  myScript
) => {
  const Params = {};
  if (chatScriptQueryString !== '') {
    // eslint-disable-next-line no-useless-escape
    const queryString = myScript.src.replace(/^[^\?]+\??/, '');
    if (!queryString) return Params; // return empty object
    const Pairs = queryString.split(/[;&]/);
    for (let i = 0; i < Pairs.length; i += 1) {
      const KeyVal = Pairs[i].split('=');
      if (!KeyVal || KeyVal.length !== 2) continue;
      const key = decodeURIComponent(KeyVal[0]);
      let val = decodeURIComponent(KeyVal[1]);
      val = val.replace(/\+/g, ' ');
      Params[key] = val;
    }
  }
  return Params;
};

export const getConfigParameters = () => {
  // parses e.g. ?platform=portal&client=12345
  const scripts = document.getElementsByTagName('script');
  let chatScriptQueryString = '';
  let loadScript = {};
  for (let i = 0; i < scripts.length; i += 1) {
    if (scripts[i].hasAttribute('data-chat-parameters')) {
      loadScript = scripts[i];
      // get the parameter string
      chatScriptQueryString = loadScript.src.replace(/^[^\?]+\??/, '');
    }
  }
  // compile parameters to object
  const parsed = chatScriptQueryString !== ''
    ? parseJsScriptQuery(chatScriptQueryString, loadScript)
    : false;
  let Params;
  // platform options: portal, pethealth, client
  if (parsed !== false) {
    Params = {
      valid: true,
      platform: parsed.platform,
      clientId: parsed.client,
      libraryUrl: parsed.libraryUrl,
    };
  } else {
    // default to portal
    Params = {
      valid: false,
      platform: 'pethealth',
      clientId: 0,
      libraryUrl: '',
    };
  }
  return Params;
};

/**
 * generates UUID
 * @returns {string} - the uuid
 */
export function GenerateGuid() {
  const newGuid = uuidv4();
  return newGuid;
}

export const chatObjectToSearchString = (chatObject, delimiter) => {
  let searchString = '';
  const topic = chatObject.topic;
  searchString += topic;
  searchString += delimiter;
  const messages = chatObject.messages;
  messages.forEach((message, index) => {
    searchString += message.content;
    if (index < messages.length - 1) searchString += delimiter;
  });
  return searchString;
};

export const searchStringToChatObject = (searchString, delimiter) => searchString.split(delimiter);

const createChatDataFromChatObject = (chat) => {
  let chatData = chat.topic;
  chat.messages.forEach((message) => {
    chatData += message.content;
  });
  return chatData;
};
const isSearchedStringInChatData = (chatData, searchedStringData) => chatData.includes(searchedStringData);

const getSearchedStringContent = (searchChatString, delimiter, chatData) => {
  const searchedChatStringsArray = searchChatString.split(delimiter);
  const searchResults = [];
  searchedChatStringsArray.forEach((s) => {
    if (isSearchedStringInChatData(chatData, s)) {
      searchResults.push(s);
    }
  });

  return searchResults;
};

export const onSearchChat = (searchedChatStrings, chats, delimiter) => {
  const searchResults = [];
  chats.forEach((chat) => {
    const chatData = createChatDataFromChatObject(chat);
    searchedChatStrings.forEach((s) => {
      searchResults.push(getSearchedStringContent(s, delimiter, chatData));
    });
  });
};

/**
 * returns chat history in reverse chronological order
 * @param {ChatHistoryArray} chatHistory - array of chat histories
 * @returns {ChatHistoryArray} - the chat histories in reverse chronological order
 */
const sortChatsByDate = (chatHistory) => chatHistory.sort((a, b) => new Date(b.end_time) - new Date(a.end_time));

export const monthNames = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

/**
 * Organizes the chat histories by month and year
 * @param {ChatHistoryArray} chatHistory - array of chat histories
 * @returns {ChatHistoryArray} - organized chat histories by month and year
 */
export function getChatHistorySections(chatHistory) {
  // sorted chats by end date in reverse chrom
  const x = sortChatsByDate(chatHistory);
  const chatSectionsSorted = [];
  let currentYearMonth = '';
  x.forEach((chat) => {
    const chatEndDateTime = chat.end_time.concat('Z');
    const tempDate = new Date(chatEndDateTime);
    const year = tempDate.getFullYear();
    const month = tempDate.getMonth();
    // if its the first entry in the chatSectionSorted array then
    if (currentYearMonth === '') {
      currentYearMonth = `${monthNames[month]} ${year}`;
      chatSectionsSorted.push({
        yearAndMonth: currentYearMonth,
        chats: [chat]
      });
    }
    // else if the current chat was started in the same month and year as the previous chat then
    else if (currentYearMonth === `${monthNames[month]} ${year}`) {
      chatSectionsSorted[chatSectionsSorted.length - 1].chats.push(chat);
    }
    // else the current chat's current year and month are new, so update the currentYearMonth and add a new object to the array
    else {
      currentYearMonth = `${monthNames[month]} ${year}`;
      chatSectionsSorted.push({
        yearAndMonth: currentYearMonth,
        chats: [chat]
      });
    }
  });
  return chatSectionsSorted;
}
/**
 * Returns date time string properly formatted for ChatHistoryLInk
 * @param {string} dateTimeString - date time string
 */

export const formatDateTimeStringForChatHistoryLink = (dateTimeString, isMobile) => {
  // Appending Z to end of string to indicate time is in UTC format
  const date = new Date(`${dateTimeString}Z`);

  // Function to get the ordinal suffix
  const getOrdinal = (day) => {
    const j = day % 10;
    const k = day % 100;
    if (j === 1 && k !== 11) {
      return `${day}st`;
    }
    if (j === 2 && k !== 12) {
      return `${day}nd`;
    }
    if (j === 3 && k !== 13) {
      return `${day}rd`;
    }
    return `${day}th`;
  };

  // Formatting the date and time
  const month = (date.getMonth() + 1).toString().length === 1 ? `0${(date.getMonth() + 1).toString()}` : `${(date.getMonth() + 1).toString()}`;
  const day = date.getDate().toString().length === 1 ? `0${date.getDate().toString()}` : `${date.getDate().toString()}`;
  const year = date.getFullYear();

  return isMobile
    ? `${month}/${day}`
    : `${month}/${day}/${year}`;
};

export const formatInnerHtml = (html) => {
  // double-line breaks
  let returnString = html;
  returnString = returnString.replace(/(\r\n|\n){2}/g, '</p><p>');
  // handle single newlines by replacing them with <br>:
  returnString = returnString.replace(/(\r\n|\n)/g, '<br>');
  // wrap all in <p> tags
  returnString = `<p>${returnString}</p>`;
  returnString = returnString.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  return returnString;
};

export const returnSearchChatHistoryData = (query, chats) => {
  // temp data
  const tempSearchData = [];

  if (query === '') {
    return { tempSearchData, searchListOpen: false };
  }

  chats.forEach((chat) => {
    const conversation = chat.conversation;
    const topic = chat.topic.toLowerCase();
    if (topic.includes(query)) {
      tempSearchData.push({ id: chat.chat_id, topic: chat.topic });
    } else {
      conversation.forEach((c) => {
        if (c[1].choices[0].message.content.toLocaleLowerCase().includes(query)) {
          tempSearchData.push({ id: chat.chat_id, topic: chat.topic });
        }
      });
    }
  });

  return {
    tempSearchData,
    searchListOpen: tempSearchData.length > 0
  };
};
