import React from 'react';
import { createSvgIcon } from '@mui/material';

const FourSvg = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
    <defs>
      <clipPath id="clip-Artboard_4">
        <rect width="28" height="28" />
      </clipPath>
    </defs>
    <g id="Artboard_4" data-name="Artboard – 4" clipPath="url(#clip-Artboard_4)">
      <text id="_4" data-name="4" transform="translate(10 19)" fill="currentColor" fontSize="14" fontFamily="Poppins-Regular, Poppins" fontWeight="bold"><tspan x="0" y="0">4</tspan></text>
    </g>
  </svg>
);

export default FourSvg;
