import React from 'react';
import { createSvgIcon } from '@mui/material';

const TwoSvg = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
    <defs>
      <clipPath id="clip-Artboard_2">
        <rect width="28" height="28" />
      </clipPath>
    </defs>
    <g id="Artboard_2" data-name="Artboard – 2" clipPath="url(#clip-Artboard_2)">
      <text id="_2" data-name="2" transform="translate(10.5 19.313)" fill="currentColor" fontSize="14" fontFamily="Poppins-Regular, Poppins" fontWeight="bold"><tspan x="0" y="0">2</tspan></text>
    </g>
  </svg>
);

export default TwoSvg;
