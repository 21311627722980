import React from 'react';
import {
  Box, Button, SvgIcon, Tooltip, Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import OneSvg from '../../../../assets/SvgComponents/OneSvg';
import TwoSvg from '../../../../assets/SvgComponents/TwoSvg';
import ThreeSvg from '../../../../assets/SvgComponents/ThreeSvg';
import FourSvg from '../../../../assets/SvgComponents/FourSvg';
import FiveSvg from '../../../../assets/SvgComponents/FiveSvg';
import SixSvg from '../../../../assets/SvgComponents/SixSvg';
import SevenSvg from '../../../../assets/SvgComponents/SevenSvg';
import EightSvg from '../../../../assets/SvgComponents/EightSvg';
import NineSvg from '../../../../assets/SvgComponents/NineSvg';

const svgComponents = [
  OneSvg, TwoSvg, ThreeSvg, FourSvg, FiveSvg,
  SixSvg, SevenSvg, EightSvg, NineSvg
];

const formatTitle = (title) => {
  if (!title) return '';
  const trimmedTitle = title.substring(0, 20).trim();
  return trimmedTitle.charAt(0).toUpperCase() + trimmedTitle.slice(1).toLowerCase();
};

const Citation = (props) => {
  let curTitle;
  let curSummary;
  const {
    title,
    titleNumber,
    summary,
    link
  } = props;

  if (title.length > 20) {
    /*     curTitle = `${title.substring(0, 20).trim()}...`; */
    curTitle = `${formatTitle(title)}...`;
  } else {
    curTitle = formatTitle(title);
  }
  if (summary) curSummary = `${summary.substring(0, 200).trim()}...`;

  const renderSvgIcon = () => {
    /* if (titleNumber < 1 || titleNumber > 9) return null; */

    const SvgIconComponent = svgComponents[titleNumber - 1];

    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: 'transparent',
          border: 'solid',
          borderRadius: '11px',
          borderWidth: '1px',
          borderColor: 'secondary.main',
          color: 'secondary.main',
          fontFamily: 'proxima-nova',
          fontWeight: '15px',
          height: '18px',
          width: '18px'
        }}
      >
        {titleNumber}
        {/* <SvgIcon component={SvgIconComponent} color="secondary" /> */}
      </Box>
    );
  };

  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: {
            p: 0,
            backgroundColor: '#ffffff',
            border: '1px solid #BFBFBF',
            maxWidth: '375px',
            borderRadius: '15px',
          },
        },
      }}
      title={(
        <>
          <Typography
            sx={{
              fontSize: '1.5625rem',
              fontWeight: 'normal',
              color: '#072C44',
              pb: 1,
              pt: 2.5,
              px: 2.5,
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: '1.0625rem',
              color: '#072C44',
              pb: 6.25,
              px: 2.5,
            }}
          >
            {curSummary}
          </Typography>
        </>
      )}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={0.5}
      >
        {renderSvgIcon()}
        <Button
          sx={{
            color: '#022740',
            fontSize: '0.9375rem',
            fontWeight: 'normal',
            py: 0.5,
            px: 1.5,
            border: '1px solid #CCCCCC',
            borderRadius: '10px',
            opacity: '50%',
            maxWidth: '200px',
            textTransform: 'none',
            justifyContent: 'flex-start',
            mr: 1.25,
            '&:hover': {
              backgroundColor: 'secondary.main',
              color: '#ffffff',
            },
          }}
          href={link}
          target="_blank"
        >
          {curTitle}
        </Button>
      </Box>
    </Tooltip>
  );
};

Citation.propTypes = {
  title: PropTypes.string.isRequired,
  titleNumber: PropTypes.number,
  summary: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

Citation.defaultProps = {
  titleNumber: null
};

export default Citation;
