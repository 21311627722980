import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Paper, InputBase, Box, ClickAwayListener
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import useTheme from '@mui/material/styles/useTheme';
import SearchList from './SearchList';

/**
 * SearchBar Component
 * @param {object} props - The props for the SearchBar component
 * @param {string} props.defaultValue - The default search text
 * @param {Array} props.searchData - The data to search through
 * @param {Function} props.onSearch - Callback function to handle the search input
 * @returns {JSX.Element} The SearchBar component
 */
const SearchBar = (props) => {
  const { defaultValue, searchData, onSearch } = props;
  const [searchValue, setSearchValue] = useState('');
  const [open, setOpen] = useState(false);
  const listRef = useRef(null);

  const handleClickAway = () => {
    setOpen(false);
    setSearchValue('');
  };

  useEffect(() => {
    if (searchData.length === 0)setOpen(false);
    if (searchData.length > 0)setOpen(true);
  }, [searchData.length]);

  // Handle input change
  const handleInputChange = (ev) => {
    const target = ev.target;
    const query = target ? target.value.toLowerCase() : '';
    // Avoid searching for empty or whitespace-only strings
    if (query === '') {
      setSearchValue('');
      onSearch(''); // Clear the search results or make any necessary updates
      return;
    }

    setSearchValue(query);
    onSearch(query);
  };

  // Handle form submission (e.g., pressing Enter or clicking the search button)
  const handleSubmit = (ev) => {
    ev.preventDefault(); // Prevent default form submission

    if (searchValue.trim() !== '') {
      onSearch(searchValue); // Submit the search query
    } else {
      onSearch(''); // Clear search if input is empty
    }
  };

  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'inline-block',
        position: 'relative',
        backgroundColor: 'transparent',
        paddingRight: '150px',
        [theme.breakpoints.down('md')]: { paddingRight: '3%', display: 'flex' },
      }}
    >
      <Paper
        component="form"
        onSubmit={handleSubmit}
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: 400,
          background: '#FFFFFF26',
          borderColor: theme.palette.white.main,
          border: theme.palette.white.main,
          borderStyle: 'solid',
          borderWidth: 'thin',
          borderRadius: '100px',
          [theme.breakpoints.down('md')]: { width: 'max-content' },
          flexGrow: 1
        }}
      >
        <InputBase
          sx={{ ml: 1, flex: 1, color: theme.palette.white.main }}
          placeholder={defaultValue || 'Search Chat History'}
          value={searchValue}
          onChange={handleInputChange}
          startAdornment={(
            <IconButton
              type="button"
              sx={{ p: '10px', color: theme.palette.white.main }}
              aria-label="search"
            >
              <SearchIcon />
            </IconButton>
        )}
        />

      </Paper>
      {(open) && (
        <ClickAwayListener onClickAway={handleClickAway}>
          <SearchList
            items={searchData}
            ref={listRef}
          />
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default SearchBar;

SearchBar.propTypes = {
  defaultValue: PropTypes.string,
  searchData: PropTypes.arrayOf(PropTypes.shape({
    chatId: PropTypes.string.isRequired,
    topic: PropTypes.string.isRequired
  })).isRequired,
  onSearch: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {
  defaultValue: '',
};
