/* eslint-disable max-len */
import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import SearchBar from '../../SearchBar/SearchBar';
import { ChatProps } from '../../../../api/SofieChat/PropTypes';
import { returnSearchChatHistoryData } from '../../../../helpers/SofieChat/utilities';

const ChatHistoryHeader = (props) => {
  const { chats, setSearchListOpen } = props;
  const [searchData, setSearchData] = useState([]);
  const theme = useTheme();

  const searchChats = (query) => {
    const { tempSearchData, searchListOpen } = returnSearchChatHistoryData(query, chats);
    setSearchData(tempSearchData);
    setSearchListOpen(searchListOpen);
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      p="2%"
      sx={{
        [theme.breakpoints.up('sm')]: { width: '100%' },
        [theme.breakpoints.down('sm')]: { width: '75%', justifyContent: 'unset' },
      }}
    >
      <Grid
        container
        sx={{
          width: '50%',
          /* [theme.breakpoints.down('sm')]: { width: '25%' } */
        }}
      >
        <Grid item sx={{ marginRight: '20px' }}>
          <RestoreIcon
            color="secondary"
            sx={{
              fontSize: {
                xs: '40px',
                md: '60px',
                [theme.breakpoints.down('md')]: { width: '100%' }
              }
            }}
          />
        </Grid>
        <Grid item>
          <Typography
            variant="h3"
            sx={{
              fontWeight: '400px',
              [theme.breakpoints.up('md')]: { width: '100%' },
              [theme.breakpoints.down('md')]: { fontSize: 'x-large' }
            }}
            color="white !important"
          >
            Chat History
          </Typography>
        </Grid>
        {/* <Grid item><Typography component="span" sx={{ fontSize: '45px', fontWeight: 'bold' }} color="white !important">Chat History</Typography></Grid> */}
      </Grid>

      <SearchBar onSearch={searchChats} searchData={searchData} />

    </Box>
  );
};

ChatHistoryHeader.propTypes = {
  chats: PropTypes.arrayOf(ChatProps),
  setSearchListOpen: PropTypes.func.isRequired
};
ChatHistoryHeader.defaultProps = { chats: [] };

export default ChatHistoryHeader;
