/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Link } from '@mui/material';
import './Answer.scss';
/* import { getCitationFilePath } from '../../api'; */

/**
 * desc
 * @param {string} answer - string
 * @param {boolean} isStreaming - boolean
 * @param {string} anchorIdentifier - identifies the anchor point
 * @returns {{ answerHtml: string, citations: string[] }}
 * returns the function
 */
const parseAnswerToHtml = (
  answer,
  isStreaming,
  anchorIdentifier
) => {
  const citations = [];

  const handleClick = (event) => {
    event.preventDefault();
    const element = document.getElementById(anchorIdentifier);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth'
      });
    }
  };

  // trim any whitespace from the end of the answer after removing follow-up questions
  let parsedAnswer = answer.trim();

  // Omit a citation that is still being typed during streaming
  if (isStreaming) {
    let lastIndex = parsedAnswer.length;
    for (let i = parsedAnswer.length - 1; i >= 0; i -= 1) {
      if (parsedAnswer[i] === ']') {
        break;
      } else if (parsedAnswer[i] === '[') {
        lastIndex = i;
        break;
      }
    }
    const truncatedAnswer = parsedAnswer.substring(0, lastIndex);
    parsedAnswer = truncatedAnswer;
  }

  const parts = parsedAnswer.split(/\[([^\]]+)\]/g);

  const fragments = parts.map((part, index) => {
    if (index % 2 === 0) {
      return part;
    }

    const firstDigit = part.match(/\d/);
    const indexFirstInt = part.indexOf(firstDigit);
    const trimmedPart = part.substring(indexFirstInt);
    const partArray = trimmedPart.split(',');

    return partArray.map((trimmedPartItem) => {
      let citationIndex;
      const trimmedId = trimmedPartItem.split('_')[0].trim();
      if (citations.indexOf(trimmedId) !== -1) {
        citationIndex = citations.indexOf(trimmedId) + 1;
      } else {
        citations.push(trimmedId);
        citationIndex = citations.length;
      }

    /* const path = getCitationFilePath(part); */

      return renderToStaticMarkup(
        <Link
          className="supContainer"
          title={trimmedId}
          onClick={handleClick}
        >
          <sup>{citationIndex}</sup>
        </Link>
      );
    });
  });

  return {
    answerHtml: fragments.join(''),
    citations,
  };
};

export default parseAnswerToHtml;
