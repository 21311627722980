import React, { useEffect } from 'react';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { attemptLogin } from '../../actions/authentication/authentication';
import LoadingPage from '../LoadingPage/LoadingPage';

const Login = (props) => {
  const { user, history, login } = props;
  const now = Math.floor(new Date().getTime() / 1000);
  const isUuidValid = user.azureUUID && user.azureUUIDExpiry - now > 0;
  const isAccessTokenValid = user.token && user.tokenExpiry - now > MODAL_TIMER;

  useEffect(() => {
    if (!isUuidValid) {
      history.push('/');
    } else if (isAccessTokenValid) {
      if (window.localStorage.getItem('sofie_version') === '3') {
        window.location.href = `${APP_URL}/sofie-chat`;
      } else {
        window.location.href = `${APP_URL}/ask`;
      }
    } else if (!isAccessTokenValid) {
      login(user.token, user.azureUUID);
    }
  }, [user.token]);
  return <LoadingPage title="Retrieving Access Token." />;
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
const mapStateToProps = (state) => ({
  user: state.user,
});

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{logout: *, authToken: *, login: *}} - bound action creators
 */
const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
    login: attemptLogin,
  },
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(Login);

Login.propTypes = {
  user: Proptypes.shape({
    token: Proptypes.string,
    tokenExpiry: Proptypes.number,
    azureUUID: Proptypes.string,
    azureUUIDExpiry: Proptypes.number,
    app_version: Proptypes.string,
  }).isRequired,
  login: Proptypes.func.isRequired,
  history: Proptypes.shape({
    push: Proptypes.func,
  }).isRequired,
};
