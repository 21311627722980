import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { toast } from 'react-toastify';
import Notification from '../Notification/Notification';
import AskAnswer from '../AskAnswer/AskAnswer';
import ShareArticleModal from '../Modals/ShareArticleModal/ShareArticleModal';
import AuthRequest from '../../api/AuthRequest';
import { showMore } from '../../actions/ask/askActions';
import FavoriteRemovalModal from '../Modals/FavoriteRemovalModal/FavoriteRemovalModal';
import {
  closeFavoriteConfirmationModal,
  removeFavourite,
} from '../../actions/addFavourite/addFavourite';

const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'middle',
})(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  maxWidth: '100vw',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: theme.spacing(4.5),
    paddingRight: theme.spacing(10.875),
    maxWidth: '1200px',
  },
}));

const CompareButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'middle',
})(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(3),
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(2),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  fontWeight: 'bold',
  fontSize: '1rem',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    width: 'auto',
  },
}));

const AnswerList = (props) => {
  const {
    type,
    answers,
    checked,
    showedMore,
    dispatchShowedMore,
    modalOpen,
    modalTitle,
    modalId,
    dispatchRemoveFavourite,
    dispatchCloseFavoriteConfirmationModal,
  } = props;

  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [shareAtricleModalOpen, setShareAtricleModalOpen] = useState(false);
  const [shareArticleModalDetails, setShareAtricleModalDetails] = useState({
    title: '',
    subtitle: '',
    docId: '',
    publisher: '',
    link: '',
  });
  let answerList = 'There are no results to display!';

  const handleOpenShareArticles = (title, subtitle, docId, publisher, link) => {
    setShareAtricleModalOpen(true);
    setShareAtricleModalDetails({
      title,
      subtitle,
      docId,
      publisher,
      link,
    });
  };

  if (type === 'ask') {
    const length = showedMore ? 20 : 10;
    const answersSliced = answers.slice(0, length);

    answerList = answersSliced.map((answer, index) => (
      <Box key={answer.id}>
        <AskAnswer
          id={answer.id}
          title={answer.title}
          book={answer.bookInfo[0].book}
          publisher={answer.bookInfo[0].publisher_name}
          year={answer.bookInfo[0].year}
          link={answer.bookInfo[0].website}
          body={answer.text}
          openShareArticles={handleOpenShareArticles}
          first={index === 0}
          buttonsPosition={isMobile ? 'title' : 'author'}
          url={`/view-articles/${answer.id}`}
          popupRequired
        />
        <Box
          sx={{
            borderBottom: '1px solid',
            ml: { xs: 3.75, lg: 7.5, xl: 9.375 },
            mr: 3.75,
          }}
        />
      </Box>
    ));
  }

  const handleCloseShareArticles = (
    reason,
    id = null,
    emails = null,
    message = null,
    title = null,
    subtitle = null,
    callback
  ) => {
    switch (reason) {
      case 'cancel':
        setShareAtricleModalOpen(false);
        break;
      case 'send':
        AuthRequest({
          method: 'post',
          url: 'share-article',
          data: {
            email: emails,
            body: message,
            title,
            subtitle,
            doc_id: id,
          },
        }).then(() => {
          setShareAtricleModalOpen(false);
          callback();
          ReactGA.event({
            category: 'article-view',
            action: 'Share',
          });
          ReactGA4.event('share-article');
          /* toast.success(
            <Notification
              title="Article Shared to Specified Recipients"
              body=""
            />
          ); */
        });
        break;
      default:
        setShareAtricleModalOpen(false);
        break;
    }
  };

  const handleCompareArticles = () => {
    ReactGA.event({
      category: 'Ask',
      action: 'compare',
    });
    ReactGA4.event('compare-article');
    const uri = encodeURIComponent(checked.join(','));
    history.push(`/view-articles/${uri}`);
  };
  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <CompareButton
          onClick={handleCompareArticles}
          variant="contained"
          color="secondary"
          disabled={checked.length < 2}
        >
          Compare
        </CompareButton>
      </Box>
      {answerList}
      <Box
        my={4}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {!showedMore && (
          <Button
            onClick={dispatchShowedMore}
            variant="contained"
            color="secondary"
            sx={{
              px: 3,
              py: 1,
              textTransform: 'none',
              fontWeight: 'bold',
              fontSize: '1rem',
              color: 'white.main',
            }}
          >
            Show More
          </Button>
        )}
      </Box>
      {type === 'ask' ? (
        <ShareArticleModal
          open={shareAtricleModalOpen}
          title={shareArticleModalDetails.title}
          subtitle={shareArticleModalDetails.subtitle}
          publisher={shareArticleModalDetails.publisher}
          link={shareArticleModalDetails.link}
          handleClose={handleCloseShareArticles}
          id={shareArticleModalDetails.docId}
        />
      ) : (
        <></>
      )}
      <FavoriteRemovalModal
        open={modalOpen}
        title={modalTitle}
        id={modalId}
        onCancel={dispatchCloseFavoriteConfirmationModal}
        onConfirm={dispatchRemoveFavourite}
      />
    </Container>
  );
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    answers: state.searchResults.current.answers,
    checked: state.searchResults.checked,
    showedMore: state.searchResults.current.showedMore,
    modalOpen: state.favouriteLists.modal.open,
    modalTitle: state.favouriteLists.modal.title,
    modalId: state.favouriteLists.modal.id,
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{askQuestion: *, check: *, uncheck: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchShowedMore: showMore,
      dispatchCloseFavoriteConfirmationModal: closeFavoriteConfirmationModal,
      dispatchRemoveFavourite: removeFavourite,
    },
    dispatch
  );
}

AnswerList.propTypes = {
  type: PropTypes.string,
  answers: PropTypes.arrayOf(PropTypes.shape({})),
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  showedMore: PropTypes.bool.isRequired,
  dispatchShowedMore: PropTypes.func.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  modalId: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  dispatchRemoveFavourite: PropTypes.func.isRequired,
  dispatchCloseFavoriteConfirmationModal: PropTypes.func.isRequired,
};

AnswerList.defaultProps = {
  type: 'ask',
  answers: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(AnswerList);
