import React from 'react';
import { createSvgIcon } from '@mui/material';

const FiveSvg = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
    <defs>
      <clipPath id="clip-Artboard_5">
        <rect width="28" height="28" />
      </clipPath>
    </defs>
    <g id="Artboard_5" data-name="Artboard – 5" clipPath="url(#clip-Artboard_5)">
      <text id="_5" data-name="5" transform="translate(9.5 19)" fill="currentColor" fontSize="14" fontFamily="Poppins-Regular, Poppins" fontWeight="bold"><tspan x="0" y="0">5</tspan></text>
    </g>
  </svg>
);

export default FiveSvg;
