import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

const Title = (props) => {
  const {
    children, color, size, marginTop
  } = props;
  return (
    <Typography
      mt={marginTop}
      sx={{
        color,
        fontSize: `${size}rem`,
        fontWeight: 'bold',
      }}
    >
      {children}
    </Typography>
  );
};

Title.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  size: PropTypes.number,
  marginTop: PropTypes.string
};

Title.defaultProps = {
  size: 1.0625,
  marginTop: ''
};

export default Title;
