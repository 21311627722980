import React, { useState } from 'react';
import {
  Box, Grid, Typography, useMediaQuery
} from '@mui/material';
import RestoreIcon from '@mui/icons-material/Restore';
import PropTypes from 'prop-types';
import useTheme from '@mui/material/styles/useTheme';
import SearchBar from '../../SearchBar/SearchBar';
import { ChatProps } from '../../../../api/SofieChat/PropTypes';
import { returnSearchChatHistoryData } from '../../../../helpers/SofieChat/utilities';

const ChatHistoryHeaderMobile = (props) => {
  const { chats, setSearchListOpen } = props;
  const [searchData, setSearchData] = useState([]);

  const searchChats = (query) => {
    const { tempSearchData, searchListOpen } = returnSearchChatHistoryData(query, chats);
    setSearchData(tempSearchData);
    setSearchListOpen(searchListOpen);
  };
  return (
    <Box
      mb="25%"
    >

      <Box
        display="flex"
        pl="15px"
        pt="20px"
        mb="4%"
      >
        <RestoreIcon
          color="secondary"
          sx={{
            fontSize: '40px',
          }}
        />
        <Typography
          variant="h3"
          sx={{
            fontWeight: 'bold',
            fontSize: '35px'
          }}
          color="white !important"
        >
          Chat History
        </Typography>
      </Box>
      <Box
        pl="15px"
        mt="3%"
        mb="4%"
      >
        <SearchBar onSearch={searchChats} searchData={searchData} />
      </Box>
    </Box>

  );
};

ChatHistoryHeaderMobile.propTypes = {
  chats: PropTypes.arrayOf(ChatProps),
  setSearchListOpen: PropTypes.func.isRequired
};
ChatHistoryHeaderMobile.defaultProps = { chats: [] };

export default ChatHistoryHeaderMobile;
