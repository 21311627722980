import React from 'react';
import { createSvgIcon } from '@mui/material';

const SixSvg = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
    <defs>
      <clipPath id="clip-Artboard_6">
        <rect width="28" height="28" />
      </clipPath>
    </defs>
    <g id="Artboard_6" data-name="Artboard – 6" clipPath="url(#clip-Artboard_6)">
      <text id="_6" data-name="6" transform="translate(9.5 19)" fill="currentColor" fontSize="14" fontFamily="Poppins-Regular, Poppins" fontWeight="bold"><tspan x="0" y="0">6</tspan></text>
    </g>
  </svg>
);

export default SixSvg;
