import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, IconButton, InputAdornment, TextField, useTheme, useMediaQuery
} from '@mui/material';
import ArrowForward from '@mui/icons-material/ArrowForward';
import AddCommentOutlined from '@mui/icons-material/AddCommentOutlined';
import MicNoneOutlined from '@mui/icons-material/MicNoneOutlined';
import { useHistory } from 'react-router-dom';

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import SuggestionMessages from '../SuggestionMessages/SuggestionMessages';

const ChatControls = (props) => {
  const {
    onClickArrow, onClickNewChat, isTextfieldDisabled, isNewPage, toggleSuggestions
  } = props;
  const [chatValue, setChatValue] = useState('');
  const [prevChatValue, setPrevChatValue] = useState('');
  const {
    transcript, listening, resetTranscript, browserSupportsSpeechRecognition
  } = useSpeechRecognition();
  const searchRef = useRef(null);
  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  /**
   * Handles updating the chat value for the Sofie Chat input field.
   *  @param {newValue} newValue - passing the new chat value
   */
  const updateChatValue = (newValue) => {
    newValue.slice(prevChatValue.length); // Get new chars only
    setChatValue(newValue);
    setPrevChatValue(newValue); // Update previous value to the current value
    resetTranscript(); // Clear the transcript from SpeechRecognition
  };

  /**
   * Handles the initialization of the voice feature for the Sofie Chat input field.
   */
  const handleStartListening = () => {
    SpeechRecognition.startListening({ continuous: true, interimResults: true });

    // Update the chatvalue
    updateChatValue(chatValue + transcript);
  };

  /**
   * Handles stopping the voice feature for the Sofie Chat input field.
   */
  const handleStopListening = () => {
    SpeechRecognition.stopListening();
  };

  const onClickSuggestion = (suggestion) => {
    toggleSuggestions(false);
    /* updateChatValue(chatValue + transcript + suggestion); */
    /* searchRef.current.focus(); */
    onClickArrow(chatValue + transcript + suggestion);
  };

  /**
   * Handles the rendering of the Mic button
   */
  const renderMicButton = () => {
    if (!browserSupportsSpeechRecognition) return null;

    return (
      <IconButton
        onClick={listening ? handleStopListening : handleStartListening}
        sx={{
          backgroundColor: listening ? 'tertiary.main' : '',
          color: 'darkGrey',
          '&:hover': { backgroundColor: 'tertiary.main', color: 'white.main' },
          '&:focus': {
            outline: 'none',
          },

          '&:focusVisible, &.Mui-focusVisible': {
            outline: 'none !important',
          },
        }}
        disabled={isTextfieldDisabled}
      >
        <MicNoneOutlined />
      </IconButton>
    );
  };

  /**
   * Handles form submission.
   */
  const onSubmit = () => {
    // Check if either is not empty
    if (chatValue.trim() !== '' || transcript.length !== 0) {
      // Remove suggestions
      toggleSuggestions(false);
      // Send the combined value to the parent
      onClickArrow(chatValue + transcript);
      // Clear the chat value and stop listening
      updateChatValue('');
      setChatValue('');
      handleStopListening();
    }
  };

  return (
    <Box
      display="flex"
      gap={2}
      flexDirection="column"
      alignItems="stretch"
      sx={{
        backgroundColor: '#FFFFFF',
      }}
      position={isNewPage ? 'static' : 'sticky'}
      bottom={isNewPage ? 'auto' : '0px'}
    >
      {isNewPage && <SuggestionMessages onClickSuggestion={onClickSuggestion} />}
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        gap={!isMobile ? 2 : 1}
        marginX={!isMobile ? 7 : 1}
      >
        <IconButton
          onClick={() => {
            // Reset the state variables
            updateChatValue('');
            onClickNewChat();
            history.push('/sofie-chat');
          }}
          sx={{
            transform: 'scaleX(-1)',
            backgroundColor: 'secondary.main',
            color: '#FFFFFF',
            '&:hover': { backgroundColor: '#C86E1D' },
            '&:focus': {
              outline: 'none',
            },

            '&:focusVisible, &.Mui-focusVisible': {
              outline: 'none !important',
            },

          }}
          disabled={isTextfieldDisabled}
        >
          <AddCommentOutlined />
        </IconButton>
        <TextField
          inputRef={searchRef}
          disabled={isTextfieldDisabled || listening}
          fullWidth
          multiline
          InputProps={{
            style: {
              borderRadius: '30px',
              caretColor: '#DC3545',
              backgroundColor: '#F5F8F9'
            },
            startAdornment: <InputAdornment position="start">{renderMicButton()}</InputAdornment>,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  disabled={isTextfieldDisabled || listening}
                  onClick={onSubmit}
                  sx={{
                    '&:focus': {
                      outline: 'none',
                    },

                    '&:focusVisible, &.Mui-focusVisible': {
                      outline: 'none !important',
                    },
                  }}
                >
                  <ArrowForward />
                </IconButton>
              </InputAdornment>
            ),
          }}
          placeholder="What would you like to ask?"
          onChange={(event) => {
            updateChatValue(event.target.value);
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter' && !event.shiftKey) {
              event.preventDefault();
              onSubmit();
            }
          }}
          value={
            listening
              ? chatValue + (transcript.length ? (chatValue.length ? ' ' : '') + transcript : '')
              : chatValue + transcript
          }
        />
      </Box>
    </Box>
  );
};

ChatControls.propTypes = {
  onClickArrow: PropTypes.func.isRequired,
  onClickNewChat: PropTypes.func.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  suggestions: PropTypes.arrayOf(PropTypes.string),
  isTextfieldDisabled: PropTypes.bool.isRequired,
  isNewPage: PropTypes.bool,
  toggleSuggestions: PropTypes.func.isRequired
};
ChatControls.defaultProps = {
  suggestions: [''],
  isNewPage: true
};
export default ChatControls;
