import React from 'react';
import { Box, keyframes } from '@mui/material';

const LoadingDots = () => {
  const spin = keyframes`
  0% {
    content: "";
  }
  25% {
    content: ".";
  }
  50% {
    content: "..";
  }
  75% {
    content: "...";
  }
  100% {
    content: "";
  }
`;
  return (
    <Box
      component="span"
      sx={{
        '&::after': {
          content: '""',
          animation: `${spin} 1s infinite`,
          width: '30px',
          display: 'inline-block'
        }
      }}
    />
  );
};

export default LoadingDots;

LoadingDots.protoTypes = {};
LoadingDots.defaultPrps = {};
