import * as actions from '../actions';
import { deleteChatAPI, getChatHistoriesAPI } from '../../api/SofieChat/ChatAppRequest';
// ==== ACTION CREATORS ====
/**
 * Action creator for sending a request to retrieve list of chats.
 * @returns {object} - action object
 */
function chatHistoryRequest() {
  return {
    type: actions.CHAT_HISTORY_REQUEST
  };
}

/**
 * Action creator for retrieving list of chats.
 * @param {Array} chatHistories - Contains the list of chatHistory
 * @returns {object} - action object
 */
function chatHistorySuccess(chatHistories) {
  return {
    type: actions.CHAT_HISTORY_SUCCESS,
    payload: {
      chatHistories
    }
  };
}

/**
 * Action creator for failing to load list of chats.
 * @returns {object} - action object
 * @param {string} errorLoad - error string
 */
function chatHistoryFailure(errorLoad) {
  return {
    type: actions.CHAT_HISTORY_FAILURE,
    payload: {
      errorLoad
    }
  };
}

/**
 * Action creator for sending a request to delete a chat
 * @returns {object} - action object
 */
function deleteChatRequest() {
  return {
    type: actions.DELETE_CHAT_REQUEST
  };
}

/**
 * Action creator for updating list of chatHistories
 * @param {Array} chatHistories - Updated list of chatHistories
 * @returns {object}- action object
 */
function deleteChatSuccess(chatHistories) {
  return {
    type: actions.DELETE_CHAT_SUCCESS,
    payload: {
      chatHistories
    }
  };
}

/**
 * Action creator for failing to delete a chat
 * @param {string} errorDelete - Error message
 * @returns {object} - action object
 */
function deleteChatFailure(errorDelete) {
  return {
    type: actions.DELETE_CHAT_FAILURE,
    payload: {
      errorDelete
    }
  };
}
/**
 * Handles fetching the list of chat histories
 * @returns {Function} - updates redux store
 */
export function getChatHistories() {
  return (dispatch) => {
    dispatch(chatHistoryRequest());
    // const requestBody = {
    //   user_id: userId
    // };
    getChatHistoriesAPI().then(async (res) => {
      if (res.ok) {
        const body = await res.json();
        dispatch(chatHistorySuccess(body));
      } else {
        throw new Error(`Request failed with status ${res.status}: ${res.statusText}`);
      }
    }).catch((error) => {
      const errorString = error.toString();
      dispatch(chatHistoryFailure(errorString));
    });
  };
}

/**
 * Handles deleting a chat history
 * @param {number} chatId - ID of chat conversation to delete
 * @param {Array} chatHistories - List of current chat conversations
 * @returns {Function} - updates redux store
 */
export function deleteChat(chatId, chatHistories) {
  return (dispatch) => {
    dispatch(deleteChatRequest());
    deleteChatAPI(chatId).then((res) => {
      if (res.ok) {
        const indexOfChatToDelete = chatHistories.findIndex(
          (chatHistory) => chatHistory.chat_id === chatId
        );
        if (indexOfChatToDelete === -1) throw new Error('chat_id was not found in list of chat histories');
        const newChatHistories = [...chatHistories];
        newChatHistories.splice(indexOfChatToDelete, 1);
        dispatch(deleteChatSuccess(newChatHistories));
      } else {
        throw new Error(`Request failed with status ${res.status}: ${res.statusText}`);
      }
    }).catch((error) => {
      const errorString = error.toString();
      dispatch(deleteChatFailure(errorString));
    });
  };
}
