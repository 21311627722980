import * as actions from '../../actions/actions';

const INIT_STATE = {
  loading: false,
  chatHistories: [],
  errorLoad: '',
  errorDelete: ''
};

const chatHistoryReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case actions.CHAT_HISTORY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case actions.CHAT_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        chatHistories: action.payload.chatHistories
      };
    case actions.CHAT_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        errorLoad: action.payload.errorLoad
      };

    case actions.DELETE_CHAT_REQUEST:
      return {
        ...state
      };

    case actions.DELETE_CHAT_SUCCESS:
      return {
        ...state,
        chatHistories: action.payload.chatHistories
      };

    case actions.DELETE_CHAT_FAILURE:
      return {
        ...state,
        errorDelete: action.payload.errorDelete
      };

    default:
      return state;
  }
};

export default chatHistoryReducer;
