import React from 'react';
import { createSvgIcon } from '@mui/material';

const SevenSvg = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
    <defs>
      <clipPath id="clip-Artboard_7">
        <rect width="28" height="28" />
      </clipPath>
    </defs>
    <g id="Artboard_7" data-name="Artboard – 7" clipPath="url(#clip-Artboard_7)">
      <text id="_7" data-name="7" transform="translate(10 19)" fill="currentColor" fontSize="14" fontFamily="Poppins-Regular, Poppins" fontWeight="bold"><tspan x="0" y="0">7</tspan></text>
    </g>
  </svg>
);

export default SevenSvg;
