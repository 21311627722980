/* eslint-disable react/no-array-index-key */
import React, { forwardRef } from 'react';
import {
  List, ListItem, ListItemText, Link,
  useMediaQuery
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';

import PropTypes from 'prop-types';

/**
 * returns jsx elem
 * @param {obj} props - see desc
 * @returns {JSXe} - see desc
 */
const SearchList = forwardRef((props, ref) => {
  const { items } = props;

  const theme = useTheme();
  /* const isPhone = useMediaQuery('only screen and (max-width : 768px)');
  const isTablet = useMediaQuery(
    'only screen and (min-width : 769px) and (max-width : 992px)'
  );
  const isWeb = useMediaQuery(
    'only screen and (min-width : 993px) and (max-width : 1200px)'
  );
  const isTv = useMediaQuery(
    'only screen and (min-width : 1201px)'
  ); */
  const isPhone = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isWeb = useMediaQuery(theme.breakpoints.down('xl'));
  const isTv = useMediaQuery(theme.breakpoints.up('xl'));

  const listItems = items.map((item, index) => (
    <ListItem
      disablePadding
      key={index}
      divider
      autoFocus
      sx={{
        paddingLeft: '1%',
        paddingRight: '1%',
        paddingTop: '1.5%',
        paddingBottom: '1.5%',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.2)'
        }
      }}
    >
      <Link
        id={item.id}
        href={`/sofie-chat/${item.id}`}
        sx={{
          textDecoration: 'none !important',
        }}
      >
        <ListItemText
          primary={item.topic}
          primaryTypographyProps={{
            color: '#FFFFFF'
          }}
        >
          {item.topic}
        </ListItemText>
      </Link>
    </ListItem>
  ));

  let maxHeight = 0;
  if (isTablet || isWeb || isTv)maxHeight = 300;
  if (isPhone || isTablet)maxHeight = 100;

  return (
    <List
      ref={ref}
      sx={{
        width: '100%',
        bgcolor: '#23323D',
        position: 'absolute',
        overflowY: 'scroll',
        maxHeight,
        '& ul': { padding: 0 },
        zIndex: 100,
        // make the list fit its parent
        top: '100%',
        left: 0,
        right: 0,
        '&::-webkit-scrollbar': {
          display: 'flex',
          width: '7px'
        },
        '&::-webkit-scrollbar-track': {
          boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          borderRadius: '10px',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgba(10, 34, 102, 0.2)',
          borderRadius: '5px',
        }
      }}
      fullwidth
    >
      {listItems}
    </List>
  );
});

SearchList.displayName = 'SearchList';

export default SearchList;

SearchList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    topic: PropTypes.string,
    id: PropTypes.string,
  })).isRequired,
  listRef: PropTypes.node
};

SearchList.defaultProps = {
  listRef: null
};
