import React from 'react';
import PropTypes from 'prop-types';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import CalculateIcon from '@mui/icons-material/Calculate';
import LogoutIcon from '@mui/icons-material/Logout';
import RestoreIcon from '@mui/icons-material/Restore';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { Paper, Typography, Stack } from '@mui/material';

const BottomNavBar = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { currentCategory, currentQuestion } = props;

  const location = useLocation();

  const navigation = [
    // Disabling navigation as per Bug 3004 on ADO
    // {
    //   name: 'Ask Sofie',
    //   url:
    //     currentQuestion && currentCategory ? `/ask/${currentCategory}/${currentQuestion}` : '/ask',
    //   icon: <ChatBubbleOutlineRoundedIcon sx={{ fontSize: '2rem' }} />,
    //   active: ['/ask', '/view-articles'],
    // },
    {
      name: 'Ask Sofie',
      url: '/sofie-chat',
      icon: <ChatBubbleOutlineRoundedIcon sx={{ fontSize: '2rem' }} />,
      active: ['/sofie-chat']
    },
    {
      name: 'Chat History',
      url: '/chat-history',
      icon: <RestoreIcon sx={{ fontSize: '2rem' }} />,
      active: ['/chat-history']
    },
    {
      name: 'Vetcalculators',
      url: '/vetcalculators',
      icon: <CalculateIcon sx={{ fontSize: '2rem' }} />,
      active: ['/vetcalculators'],
    },
    {
      name: 'Favorites',
      url: '/favorites',
      icon: <StarOutlineIcon sx={{ fontSize: '2rem' }} />,
      active: ['/favorites', 'favorite-articles'],
    },
    {
      name: 'Client Education',
      external: true,
      url: CLIENTED_URL,
      icon: <AutoStoriesIcon sx={{ fontSize: '2rem' }} />,
      active: ['/client-education'],
    },
    // {
    //   name: 'Logout',
    //   url: '/logout',
    //   icon: <LogoutIcon sx={{ fontSize: '1.5rem' }} />,
    //   active: ['/logout']
    // }
  ];

  // Disabling navigation as per Bug 3004 on ADO
  // if (APP_ENV !== 'production' && APP_ENV !== 'staging') {
  //   navigation.splice(1, 0, {
  //     name: 'Differential Diagnosis',
  //     url: '/ddx',
  //     icon: <CompareArrowsIcon sx={{ fontSize: '2rem' }} />,
  //     active: ['/ddx'],
  //   });
  // }

  const clientEdNameStyle = {
    font: 'normal normal medium 25px/20px Proxima Nova',
    letterSpacing: '0px',
    color: '#FFFFFF',
    opacity: '0.5',
    position: 'absolute',

  };

  const clientEdSubNameStyle = {
    font: 'normal normal medium 17px/25px Proxima Nova',
    letterSpacing: '0px',
    color: '#FFFFFF',
    opacity: '0.5',
    position: 'absolute',
  };
  return (
    <Paper
      sx={{
        position: 'sticky',
        bottom: 0,
        left: 0,
        right: 0,
        py: 3,
        background: 'linear-gradient(180deg, rgba(0,34,60,1) 50%, rgba(0,1,1,1) 100%)',
        borderRadius: '0px',
        displayPrint: 'none'
      }}
      elevation={3}
    >
      <BottomNavigation
        showLabels
        sx={{
          alignItems: 'start',
          background: 'transparent',
        }}
      >
        {navigation.map((item) => {
          const isActive = item.active.filter((i) => location.pathname.includes(i)).length !== 0;

          let component = '';
          let componentProps = {};
          if (!item.external) {
            component = RouterLink;
            componentProps.to = item.url;
          }

          if (item.external) {
            component = 'a';
            componentProps = {
              underline: 'hover',
              target: '_blank',
              rel: 'noopener noreferrer',
              href: item.url,
            };
          }
          return (
            <BottomNavigationAction
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...componentProps}
              component={component}
              to={item.url}
              key={item.url}
              label={
                item.name === 'ClientEd' ? (
                  <>
                    <Typography component="div" fontSize="12px">{item.name}</Typography>
                    <Typography component="div" fontSize="12px">{item.subName}</Typography>
                  </>
                ) : item.name
              }
              icon={item.icon}
              sx={{
                textAlign: 'center',
                color: 'white.main',
                minWidth: '70px',
                '& .MuiSvgIcon-root': {
                  color: isActive ? 'secondary.main' : 'white.main',
                },
                '&:focus': {
                  color: 'secondary.main'
                },
                '&:hover': {
                  color: 'secondary.main'
                }
              }}
              onClick={() => {
                if (item.name === 'ClientEd') {
                  ReactGA.event({
                    category: 'Header',
                    action: 'redirect-to-cliented',
                  });
                  ReactGA4.event('redirect-to-cliented');
                }
              }}
            />
          );
        })}
      </BottomNavigation>
    </Paper>
  );
};

BottomNavBar.propTypes = {
  currentQuestion: PropTypes.string,
  currentCategory: PropTypes.string,
};

BottomNavBar.defaultProps = {
  currentQuestion: '',
  currentCategory: '',
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    currentQuestion: state.searchResults.current.question,
    currentCategory: state.searchResults.current.category,
  };
}

export default connect(mapStateToProps)(BottomNavBar);
