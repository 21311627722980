import React, {
  useMemo, useState
} from 'react';
import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';
import parseAnswerToHtml from './AnswerParser';
import AssistantBubble from '../SpeechBubble/AssistantChatMessage/AssistantChatMessage';
import { ChatAppResponseProp } from '../../../api/SofieChat/PropTypes';

const Answer = ({ answer, isStreaming, isEmergency }) => {
  const messageContent = answer.choices[0].message.content;
  const [anchorIdentifier, setAnchorIdentifier] = useState('');

  const parsedAnswer = useMemo(
    () => parseAnswerToHtml(messageContent, isStreaming, anchorIdentifier),
    [answer, isStreaming, messageContent, anchorIdentifier]
  );

  const sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
  let related = [];
  if (parsedAnswer.citations.length > 0) {
    related = parsedAnswer.citations.map((citation) => {
      // Check if sources exists and is an array before calling find
      const sources = answer.choices[0]?.sources; // Use optional chaining
      if (!sources) {
        console.error("Sources are missing or invalid");
        return null; // Skip this citation if sources is not available
      }

      const citationSource = sources.find((source) => source.id.startsWith(citation));

      // Check if citationSource is found, and handle it gracefully
      if (!citationSource) {
        console.warn(`Citation source with ID starting with ${citation} not found`);
        return null; // Skip this citation if source not found
      }

      return {
        id: parseInt(citation, 10),
        title: citationSource.title,
        summary: citationSource.summary
      };
    }).filter(item => item !== null);
  }

  return (
    <AssistantBubble
      innerHtml={sanitizedAnswerHtml}
      citations={parsedAnswer.citations}
      isEmergency={isEmergency}
      related={related}
      type="regular"
      anchorIdentifier={anchorIdentifier}
    />
  );
};

export default Answer;

Answer.propTypes = {
  answer: ChatAppResponseProp.isRequired,
  isStreaming: PropTypes.bool.isRequired,
  isEmergency: PropTypes.bool,
};

Answer.defaultProps = {
  isEmergency: false,
};
