import { combineReducers } from 'redux';
import user from './user/user';
import auth from './authentication/auth';
import searchResults from './searchResults/searchResults';
import favouriteLists from './favouriteList/favouriteList';
import navigationDetails from './navigationDetails/navigationDetails';
import chatHistoryReducer from './chatHistory/chatHistoryReducer';

const rootReducer = combineReducers({
  auth,
  user,
  searchResults,
  favouriteLists,
  navigationDetails,
  chatHistoryReducer
});

export default rootReducer;
