import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Proptypes from 'prop-types';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import Page from '../Page/Page';
import ErrorPage from '../ErrorPage/ErrorPage';
import Logout from '../Logout/Logout';
import Ask from '../../pages/Ask/Ask';
import TextPage from '../TextPage/TextPage';
import TermsPage from '../TermsPage/TermsPage';
import ProfilePage from '../PofilePage/ProfilePage';
import Publisher from '../../pages/Publisher/Publisher';
import ThirdPartyRegistration from '../ThirdPartyRegistration/ThirdPartyRegistration';
import PublicArticle from '../../pages/PublicArticle/PublicArticle';
import Favorite from '../../pages/Favorite/Favorite';
import AzureLogin from '../Login/AzureLogin';
import HandleRedirect from '../Login/HandleRedirect';
import Login from '../Login/Login';
// import DDX from '../DDX/DDX'; Disabling navigation as per Bug 3004 on ADO
import Article from '../../pages/Article/Article';
import Vetcalculators from '../../pages/Vetcalculators/Vetcalculators';
// import LandingPage from '../../pages/LandingPage/LandingPage'; Disabling naviagtion as per Bug 3004 on ADO
import SofieChat from '../../pages/SofieChat/SofieChat';
import ChatHistory from '../../pages/SofieChat/ChatHistory';

const Routes = (props) => {
  const {
    embed, credentials, token, user, forgotPassword, loginError
  } = props;
  return (
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          if (embed) {
            return <ThirdPartyRegistration credentials={credentials} />;
          }
          return <AzureLogin />;
        }}
      />
      <Route
        exact
        path="/handle-redirect"
        render={(childProps) => (
          <HandleRedirect
            history={childProps.history}
            forgotPassword={forgotPassword}
            loginError={loginError}
          />
        )}
      />
      <Route
        exact
        path="/login"
        render={(childProps) => <Login history={childProps.history} />}
      />
      <Route
        exact
        path="/public-article/:code"
        render={(childProps) => (
          <PublicArticle
            history={childProps.history}
            location={childProps.location.pathname}
            match={childProps.match}
          />
        )}
      />
      <Route
        exact
        path="/student"
        render={() => <Redirect to="/" />}
      />
      <Route
        exact
        path="/student/forgot-password"
        render={() => <AzureLogin forgotPassword />}
      />
      {/* Disabling navigation as per Bug 3004 on ADO */}
      {/* <PrivateRoute
        exact
        path="/ask"
        embed={embed}
        render={(childProps) => (
          <Page
            embed={embed}
            history={childProps.history}
            location={childProps.location.pathname}
            credentials={credentials}
          >
            <LandingPage />
          </Page>
        )}
      />
      <PrivateRoute
        exact
        path="/ask/:category/:query"
        embed={embed}
        render={(childProps) => (
          <Page
            embed={embed}
            history={childProps.history}
            location={childProps.location.pathname}
            credentials={credentials}
            showLogout={false}
          >
            <Ask
              history={childProps.history}
              match={childProps.match}
              user={user}
            />
          </Page>
        )}
      /> */}
      <PrivateRoute
        exact
        path="/sofie-chat"
        embed={embed}
        render={(childProps) => (
          <Page
            embed={embed}
            history={childProps.history}
            location={childProps.location.pathname}
            credentials={credentials}
            isSofieChat
          >
            <SofieChat />
          </Page>
        )}
      />
      <PrivateRoute
        exact
        path="/sofie-chat/:chatId"
        embed={embed}
        render={(childProps) => (
          <Page
            embed={embed}
            history={childProps.history}
            location={childProps.location.pathname}
            credentials={credentials}
            isSofieChat
          >
            <SofieChat />
          </Page>
        )}
      />
      <PrivateRoute
        exact
        path="/chat-history"
        embed={embed}
        render={(childProps) => (
          <Page
            embed={embed}
            history={childProps.history}
            location={childProps.location.pathname}
            credentials={credentials}
            paddingTop={0}
            backgroundColor="#00111E"
          >
            <ChatHistory />
          </Page>
        )}
      />
      <PrivateRoute
        exact
        path="/vetcalculators"
        embed={embed}
        render={(childProps) => (
          <Page
            embed={embed}
            history={childProps.history}
            location={childProps.location.pathname}
            credentials={credentials}
            showLogout={false}
            footer={false}
            paddingTop={0}
          >
            <Vetcalculators />
          </Page>
        )}
      />
      {/* Disabling navigation as per Bug 3004 on ADO */}
      {/* {APP_ENV !== 'production' && APP_ENV !== 'staging' && (
        <PrivateRoute
          exact
          path="/ddx"
          embed={embed}
          render={(childProps) => (
            <Page
              embed={embed}
              history={childProps.history}
              location={childProps.location.pathname}
              credentials={credentials}
            >
              <DDX
                history={childProps.history}
                match={childProps.match}
                user={user}
              />
            </Page>
          )}
        />
      )}
      {APP_ENV !== 'production' && APP_ENV !== 'staging' && (
        <PrivateRoute
          exact
          path="/ddx/:query"
          embed={embed}
          render={(childProps) => (
            <Page
              embed={embed}
              history={childProps.history}
              location={childProps.location.pathname}
              credentials={credentials}
            >
              <DDX
                history={childProps.history}
                match={childProps.match}
                user={user}
              />
            </Page>
          )}
        />
      )} */}
      <Route
        exact
        path="/logout"
        embed={embed}
        render={(childProps) => (
          <Logout
            user={user}
            location={childProps.location}
          />
        )}
      />
      <PrivateRoute
        exact
        path="/view-articles/:ids/"
        embed={embed}
        render={(childProps) => (
          <Page
            embed={embed}
            history={childProps.history}
            location={childProps.location.pathname}
            credentials={credentials}
            showLogout={false}
            paddingTop={0}
          >
            <Article
              match={childProps.match}
              embed={embed}
              history={childProps.history}
              location={childProps.location.pathname}
            />
          </Page>
        )}
      />

      <PrivateRoute
        exact
        path="/favorites"
        embed={embed}
        render={(childProps) => (
          <Page
            embed={embed}
            history={childProps.history}
            location={childProps.location.pathname}
            credentials={credentials}
          >
            <Favorite
              history={childProps.history}
              match={childProps.match}
            />
          </Page>
        )}
      />

      <PrivateRoute
        exact
        path="/favorite-articles/:ids/"
        embed={embed}
        render={(childProps) => (
          <Page
            embed={embed}
            history={childProps.history}
            location={childProps.location.pathname}
            credentials={credentials}
            showLogout={false}
            paddingTop={0}
          >
            <Article
              match={childProps.match}
              embed={embed}
              history={childProps.history}
              location={childProps.location.pathname}
            />
          </Page>
        )}
      />

      <PrivateRoute
        exact
        path="/disclaimer"
        embed={embed}
        render={(childProps) => (
          <Page
            embed={embed}
            history={childProps.history}
            location={childProps.location.pathname}
            credentials={credentials}
          >
            <TextPage />
          </Page>
        )}
      />
      <PrivateRoute
        exact
        path="/profile"
        embed={embed}
        render={(childProps) => (
          <Page
            embed={embed}
            history={childProps.history}
            location={childProps.location.pathname}
            credentials={credentials}
          >
            <ProfilePage />
          </Page>
        )}
      />
      <PrivateRoute
        exact
        path="/terms-and-conditions"
        embed={embed}
        render={(childProps) => (
          <Page
            header={false}
            footer={false}
            embed={embed}
            history={childProps.history}
            location={childProps.location.pathname}
            showLogout={false}
            showNavBar={false}
          >
            <TermsPage
              embed={embed}
              history={childProps.history}
              purina={user.accountID === 4}
            />
          </Page>
        )}
      />
      <PrivateRoute
        exact
        path="/publisher"
        embed={embed}
        render={(childProps) => (
          <Page
            embed={embed}
            history={childProps.history}
            location={childProps.location.pathname}
            credentials={credentials}
          >
            <Publisher />
          </Page>
        )}
      />
      <Route
        exact
        path=""
        render={(childProps) => {
          if (token) {
            return (
              <Page
                embed={embed}
                history={childProps.history}
                location={childProps.location.pathname}
                credentials={credentials}
              >
                <ErrorPage />
              </Page>
            );
          }
          return (
            <Page
              embed={embed}
              history={childProps.history}
              location={childProps.location.pathname}
              header={false}
              footer={false}
              heartbeat={false}
            >
              <ErrorPage />
            </Page>
          );
        }}
      />
    </Switch>
  );
};

export default Routes;

Routes.propTypes = {
  embed: Proptypes.bool,
  credentials: Proptypes.shape({
    third_party_token: Proptypes.string,
    user_details: Proptypes.shape({
      user_id: Proptypes.number,
    })
  }),
  user: Proptypes.shape({
    accountID: Proptypes.number,
  }).isRequired,
  token: Proptypes.string.isRequired,
  forgotPassword: Proptypes.bool.isRequired,
  loginError: Proptypes.string.isRequired,
};

Routes.defaultProps = {
  embed: false,
  credentials: {
    third_party_token: '',
    user_details: {
      user_id: 0
    },
  },
};
