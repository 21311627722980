import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomLink from '../../elements/CustomLink/CustomLink';
import Logo from '../../assets/svgs/logo-new.svg';

const Separator = (props) => (
  <Typography
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    sx={{ display: 'inline-block', px: 1,  color: 'gray' }}
  >
    {' | '}
  </Typography>
);

const Footer = () => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  // Determine if the current path is /favorite-articles
  const isFavoriteArticlesPage = location.pathname.startsWith('/favorite-articles/');
  return (
    <Box
      sx={{
        display: 'flex', // Hide for mobile
        justifyContent: 'center',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: isMobile ? 'center' : 'flex-end',
        py: isMobile ? 3 : 2,
        px: 2,
        displayPrint: 'none',
        textAlign: isMobile ? 'center' : 'left',
        backgroundColor: isFavoriteArticlesPage ? "#F5F7F8" : 'transparent', // Set background color
      }}
    >
    <Box>
      <CustomLink
          text="Disclaimer"
          to="/disclaimer"
          disabled={location.pathname === '/disclaimer'}
          color="link"
        />
        <Separator />
        <CustomLink
          text="Terms and Conditions"
          to="https://www.lifelearn.com/sofie-terms-and-conditions/"
          external
          color="link"
        />
        <Separator />
        <CustomLink
          text="Privacy Policy"
          to="https://www.lifelearn.com/privacy-policy/"
          external
          color="link"
        />
        <Separator />
        <CustomLink
          text="Publications"
          to="/publisher"
          disabled={location.pathname === '/publisher'}
          color="link"
        />
        <Separator />
        <CustomLink
          text="Help"
          to="https://www.lifelearn.com/sofie-resources/"
          external
          color="link"
        />
      </Box>
    </Box>
  );
};

Footer.propTypes = {};

export default Footer;
