import React from 'react';
import PropTypes from 'prop-types';

import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
  Typography
} from '@mui/material';

const DeleteChatModal = (props) => {
  const {
    chatTitle, isOpen, handleClose, handleDelete
  } = props;
  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="delete-chat-title"
      aria-describedby="delete-chat-message"
      sx={{
        '& .MuiDialog-paper': {
          padding: '50px'
        }
      }}
    >
      <DialogTitle
        id="delete-chat-title"
        textAlign="center"
      >
        Delete Chat
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" id="delete-chat-message">
          Are you sure you want to delete&nbsp;
          <Typography component="span" variant="body1" fontWeight="bold">{chatTitle}</Typography>
          ?
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'center',
          gap: 1
        }}
      >
        <Button
          onClick={handleClose}
          color="white"
          variant="contained"
          sx={{
            '&:focus': {
              outlineColor: '#D18730'
            }
          }}
        >
          Cancel
        </Button>
        <Button
          autoFocus
          onClick={handleDelete}
          color="secondary"
          variant="contained"
          sx={{
            '&:focus': {
              outlineColor: '#D18730',
              outline: 'none',
            },
            '&:active': {
              outline: 'none'        
            }
          }}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteChatModal.propTypes = {
  chatTitle: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired
};
export default DeleteChatModal;
