import React, { useEffect, useState, createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import { Box, Grid } from '@mui/material';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { toast } from 'react-toastify';
import AuthRequest from '../../api/AuthRequest';
import Notification from '../../components/Notification/Notification';
import LoadingPage from '../../components/LoadingPage/LoadingPage';
import ArticleHeader from './ArticleHeader';
import ArticleBody from './ArticleBody';
import ShareArticleModal from '../../components/Modals/ShareArticleModal/ShareArticleModal';
import Notes from '../../components/Notes/Notes';
import CompareSection from './CompareSection';
import MobileArticleHeader from './MobileArticleHeader';

const Article = (props) => {
  const { searchResults, favorites, newFavoriteId } = props;

  const params = useParams();
  const headerRef = createRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [headerStuck, setHeaderStuck] = useState(false);
  const [addedFav, setAddedFav] = useState(false);
  const [articles, setArticles] = useState([]);
  const [compareIndex, setCompareIndex] = useState(0);
  const [shareAtricleModalOpen, setShareAtricleModalOpen] = useState(false);
  const [articleSearch, setArticleSearch] = useState('');
  const [articleSearchIndex, setArticleSearchIndex] = useState(undefined);
  const [articleSearchList, setArticleSearchList] = useState({});
  const [articleSearchListLength, setArticleSearchListLength] = useState(0);
  const [shareArticleModalDetails, setShareAtricleModalDetails] = useState({
    title: '',
    subtitle: '',
    docId: '',
    publisher: '',
    link: '',
  });

  const uri = decodeURIComponent(params.ids);
  const articleIDs = uri.split(',');
  const isCompare = articleIDs.length !== 1;
  const currentArticleId = articleIDs[compareIndex];
  const isFavorite = favorites.includes(currentArticleId);
  const highlighting = searchResults.highlighting ? searchResults.highlighting.join() : undefined;

  useEffect(() => {
    if (newFavoriteId && addedFav) {
      const newArticles = JSON.parse(JSON.stringify(articles));
      newArticles[compareIndex].favoriteId = newFavoriteId;
      setArticles(newArticles);
      setAddedFav(false);
    }
  }, [newFavoriteId]);

  useEffect(() => {
    if (APP_ENV === 'production' && searchResults && searchResults.question) {
      // eslint-disable-next-line no-undef
      hj('identify', null, {
        natural_language_question: searchResults.question,
      });
    }

    if (articles.length > 0) {
      const rank = searchResults.answers.findIndex(
        (answer) => answer.title.toLowerCase() === articles[compareIndex].title.toLowerCase()
      );
      ReactGA.event({
        category: 'article-view',
        action: `${searchResults.question}:${rank}:${articles[compareIndex].bookInfo[0].id}`,
        label: articles[compareIndex].title,
      });
      ReactGA4.event('article-view', {
        article: articles[compareIndex].docID,
        rank,
        question: searchResults.question,
      });
    }
  }, [compareIndex]);

  useEffect(() => {
    // API Call to get article content
    const data = articleIDs.map((id) => {
      const articleIsFavorite = favorites.includes(id);
      return {
        docId: id,
        isFavorite: articleIsFavorite,
      };
    });
    AuthRequest({
      method: 'post',
      url: 'document',
      data: {
        document: data,
      },
    }).then((res) => {
      setArticles(res.data.message);
      const rank = searchResults.answers.findIndex(
        (answer) => answer.title.toLowerCase() === res.data.message[0].title.toLowerCase()
      );

      let gaAction;
      let ga4Action;
      if (rank === -1) {
        gaAction = `Favorites View:${rank}:${res.data.message[0].bookInfo[0].id}`;
        ga4Action = 'Favorites View';
      } else {
        gaAction = `${searchResults.question}:${rank}:${res.data.message[0].bookInfo[0].id}`;
        ga4Action = searchResults.question;
      }

      ReactGA.event({
        category: 'article-view',
        action: gaAction,
        label: res.data.message[0].title,
      });

      ReactGA4.event('article-view', {
        article: res.data.message[0].docID,
        rank,
        question: ga4Action,
      });
    });
  }, []);

  useEffect(() => {
    if (headerRef.current && !isMobile) {
      const cachedRef = headerRef.current;
      const observer = new IntersectionObserver(([e]) => setHeaderStuck(e.intersectionRatio < 1), {
        threshold: [1],
      });
      observer.observe(cachedRef);
      return () => observer.unobserve(cachedRef);
    }
    return () => false;
  }, [headerRef]);

  if (articles.length === 0) {
    let title = 'Loading Article...';
    if (isCompare) {
      title = 'Loading Articles...';
    }
    return <LoadingPage title={title} />;
  }

  const handleCloseShareArticles = (
    reason,
    id = null,
    emails = null,
    message = null,
    title = null,
    subtitle = null,
    callback
  ) => {
    switch (reason) {
      case 'cancel':
        setShareAtricleModalOpen(false);
        break;
      case 'send':
        AuthRequest({
          method: 'post',
          url: 'share-article',
          data: {
            email: emails,
            body: message,
            title,
            subtitle,
            doc_id: id,
          },
        }).then(() => {
          setShareAtricleModalOpen(false);
          callback();
          ReactGA.event({
            category: 'article-view',
            action: 'Share',
          });
          ReactGA4.event('share-article');
          /* toast.success(
            <Notification
              title="Article Shared to Specified Recipients"
              body=""
            />
          ); */
        });
        break;
      default:
        setShareAtricleModalOpen(false);
        break;
    }
  };

  const handleOpenShareArticles = () => {
    setShareAtricleModalOpen(true);
    setShareAtricleModalDetails({
      title: articles[compareIndex].title,
      subtitle: `${articles[compareIndex].bookInfo[0].book} - ${articles[compareIndex].bookInfo[0].year}`,
      docId: currentArticleId,
      publisher: articles[compareIndex].bookInfo[0].publisher_name,
      link: articles[compareIndex].bookInfo[0].website,
    });
  };

  return (
    <>
      {isMobile && (
        <MobileArticleHeader
          articleSearch={articleSearch}
          setArticleSearch={setArticleSearch}
          articleSearchIndex={articleSearchIndex}
          setArticleSearchIndex={setArticleSearchIndex}
          articleSearchListLength={articleSearchListLength}
          setArticleSearchListLength={setArticleSearchListLength}
        />
      )}

      <Grid container>
        {isCompare ? (
          <Grid
            item
            xs={12}
            lg={3}
            xl={2}
          >
            <Box
              sx={{
                backgroundColor: grey[100],
                pb: 2,
                height: '100%',
              }}
            >
              <CompareSection
                searchResults={searchResults}
                currentArticleId={currentArticleId}
                setCompareIndex={setCompareIndex}
                articleIDs={articleIDs}
                setArticleSearch={setArticleSearch}
                setArticleSearchIndex={setArticleSearchIndex}
                setArticleSearchList={setArticleSearchList}
                setArticleSearchListLength={setArticleSearchListLength}
              />
            </Box>
          </Grid>
        ) : (
          <></>
        )}
        <Grid
          item
          xs={12}
          lg={isCompare ? 9 : 12}
          xl={isCompare ? 10 : 12}
        >
          <Box
            sx={{
              position: isMobile ? 'relative' : 'sticky',
              backgroundColor: 'white.main',
              borderBottom: headerStuck ? '1px solid' : 'none',
              zIndex: isMobile ? 1 : 1000,
              top: -1,
              paddingTop: headerStuck ? '33px' : theme.spacing(6.75),
              px: { xs: 3, lg: 0 },
              '@media print': {
                position: 'relative',
                border: 'none',
              },
            }}
            pb={3}
            ref={headerRef}
          >
            <ArticleHeader
              id={currentArticleId}
              title={articles[compareIndex].title}
              book={articles[compareIndex].bookInfo[0].book}
              year={articles[compareIndex].bookInfo[0].year}
              publisherName={articles[compareIndex].bookInfo[0].publisher_name}
              publisherWebsite={articles[compareIndex].bookInfo[0].website}
              openShareArticles={handleOpenShareArticles}
              articleSearch={articleSearch}
              setArticleSearch={setArticleSearch}
              articleSearchIndex={articleSearchIndex}
              setArticleSearchIndex={setArticleSearchIndex}
              articleSearchListLength={articleSearchListLength}
              setArticleSearchListLength={setArticleSearchListLength}
              notes={articles[compareIndex].notes}
              favoriteId={articles[compareIndex].favoriteId}
              isFavorite={isFavorite}
              setAddedFav={setAddedFav}
            />
          </Box>
          <Box pb={2} />
          <Grid container>
            <Grid
              item
              xs={12}
              lg={1}
            />
            <Grid
              item
              xs={12}
              lg={isCompare ? 11 : 8}
            >
              <ArticleBody
                body={articles[compareIndex].answerbody}
                highlighting={highlighting}
                articleSearch={articleSearch}
                setArticleSearchList={setArticleSearchList}
                articleSearchIndex={articleSearchIndex}
                setArticleSearchIndex={setArticleSearchIndex}
                setArticleSearchListLength={setArticleSearchListLength}
                articleSearchList={articleSearchList}
              />
            </Grid>
            <Grid
              item
              xs={12}
              lg={3}
            >
              {isFavorite && !isCompare && !isMobile ? (
                <Notes
                  id={articles[compareIndex].favoriteId}
                  notes={articles[compareIndex].notes}
                />
              ) : (
                <></>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ShareArticleModal
        open={shareAtricleModalOpen}
        title={shareArticleModalDetails.title}
        subtitle={shareArticleModalDetails.subtitle}
        publisher={shareArticleModalDetails.publisher}
        link={shareArticleModalDetails.link}
        handleClose={handleCloseShareArticles}
        id={shareArticleModalDetails.docId}
      />
    </>
  );
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{ user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    searchResults: state.searchResults.current,
    favorites: state.user.favorites,
    newFavoriteId: state.favouriteLists.newFavoriteId,
  };
}

Article.propTypes = {
  searchResults: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.shape({})),
    highlighting: PropTypes.arrayOf(PropTypes.string),
    question: PropTypes.string,
  }),
  favorites: PropTypes.arrayOf(PropTypes.string).isRequired,
  newFavoriteId: PropTypes.string.isRequired,
};

Article.defaultProps = {
  searchResults: {
    current: {
      question: '',
    },
    answers: [{}],
    highlighting: '',
  },
};

export default connect(mapStateToProps)(Article);
