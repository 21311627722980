import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  List,
  Drawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  Link,
  Typography
} from '@mui/material';
import { useLocation, Link as RouterLink, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import { connect } from 'react-redux';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import CalculateIcon from '@mui/icons-material/Calculate';
import RestoreIcon from '@mui/icons-material/Restore';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import { grey } from '@mui/material/colors';
import Logo from '../../assets/svgs/logo-new-white.svg';
import BookIcon from '../BookIcon/BookIcon';

const Header = (props) => {
  const {
    drawerWidth, currentCategory, currentQuestion, name
  } = props;

  const history = useHistory();
  const location = useLocation();

  const navigation = [
    // Disabling navigation as per Bug 3004 on ADO
    // {
    //   name: 'Ask Sofie',
    //   url:
    //     currentQuestion && currentCategory ? `/ask/${currentCategory}/${currentQuestion}` : '/ask',
    //   icon: <ChatBubbleOutlineRoundedIcon sx={{ fontSize: '1.125rem' }} />,
    //   active: ['/ask', '/view-articles'],
    // },
    {
      name: 'Ask Sofie',
      url: '/sofie-chat',
      icon: <ChatBubbleOutlineRoundedIcon sx={{ fontSize: '1.125rem' }} />,
      active: ['/sofie-chat']
    },
    {
      // Temporary spot for chat history
      name: 'Chat History',
      url: '/chat-history',
      icon: <RestoreIcon sx={{ fontSize: '1.125rem' }} />,
      active: ['/chat-history']
    },
    {
      name: 'Vetcalculators',
      url: '/vetcalculators',
      icon: <CalculateIcon sx={{ fontSize: '1.125rem' }} />,
      active: ['/vetcalculators'],
    },
    {
      name: 'Favorites',
      url: '/favorites',
      icon: <StarOutlineIcon sx={{ fontSize: '1.125rem' }} />,
      active: ['/favorites', '/favorite-articles'],
    },
    {
      name: 'Client Education',
      external: true,
      url: CLIENTED_URL,
      icon: <BookIcon
        styleObject={{
          color: 'orange',
          fontSize: '1.125rem !important',
        }}
      />,
      active: ['/client-education'],
    },
  ];

  // Disabling navigation as per Bug 3004 on ADO
  // if (APP_ENV !== 'production' && APP_ENV !== 'staging') {
  //   navigation.splice(1, 0, {
  //     name: 'Differential Diagnosis',
  //     url: '/ddx',
  //     icon: <CompareArrowsIcon sx={{ fontSize: '2rem' }} />,
  //     active: ['/ddx'],
  //   });
  // }

  const primaryTypographyStyle = {
    font: 'normal normal medium 25px/20px Proxima Nova',
    letterSpacing: '0px',
    color: '#FFFFFF',
    opacity: '0.5',
    fontSize: '1.13rem !important',
    fontWeight: 'medium'
  };

  const secondaryTypographyStyle = {
    font: 'normal normal medium 17px/25px Proxima Nova',
    letterSpacing: '0px',
    color: '#FFFFFF',
    opacity: '0.5',
    fontSize: '0.94rem !important',
  };

  return (
    <Box
      sx={{
        displayPrint: 'none',
      }}
    >
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            background: 'linear-gradient(180deg, rgba(0,34,60,1) 50%, rgba(0,1,1,1) 100%)',
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
        PaperProps={{
          sx: {
            borderRight: 'none',
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 5,
            pb: 3,
          }}
        >
          <Link
            component={RouterLink}
            to="/sofie-chat"
            sx={{
              textDecoration: 'none',
              color: 'white.main',
              '&:hover, &:active': {
                textDecoration: 'none',
                color: 'white.main',
              },
            }}
          >
            <Typography
              sx={{
                fontSize: '3.4375rem',
                lineHeight: 1,
              }}
            >
              Sofie
            </Typography>
            <Typography
              sx={{
                fontSize: '1.25rem',
                opacity: 0.59,
              }}
            >
              Medical Search Tool
            </Typography>
          </Link>
        </Box>
        <Box
          sx={{
            pl: 4,
            pb: 2,
          }}
        >
          <Typography
            sx={{
              color: 'white.main',
              fontSize: '1.25rem'
            }}
          >
            {`Welcome, ${name}`}

          </Typography>
        </Box>
        <List>
          {navigation.map((item) => {
            const isActive = item.active.filter((i) => location.pathname.includes(i)).length !== 0;
            let component = '';
            let componentProps = {};
            if (!item.external) {
              component = RouterLink;
              componentProps.to = item.url;
            }

            if (item.external) {
              component = 'a';
              componentProps = {
                underline: 'hover',
                target: '_blank',
                rel: 'noopener noreferrer',
                href: item.url,
              };
            }

            return (
              <ListItem
                key={item.url}
                sx={{
                  '&::after': {
                    content: '" "',
                    borderLeft: isActive ? '7px solid' : 'none',
                    borderColor: 'secondary.main',
                    backgroundColor: 'secondary.main',
                    height: '100%',
                    position: 'absolute',
                    right: '0px',
                    borderTopLeftRadius: '10px',
                    borderBottomLeftRadius: '10px',
                  },
                }}
              >
                <ListItemButton
                  component={component}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...componentProps}
                  sx={{
                    color: isActive ? 'white.main' : grey[500],
                    borderRadius: '10px',
                    '&:hover': {
                      backgroundColor: isActive ? 'transparent' : 'rgba(255,255,255,0.1)',
                      cursor: 'pointer',
                      color: 'white.main',
                      '& .MuiListItemIcon-root': {
                        color: isActive ? 'secondary.main' : 'white.main',
                      },
                    },
                  }}
                  onClick={() => {
                    if (item.name === 'ClientEd') {
                      ReactGA.event({
                        category: 'Header',
                        action: 'redirect-to-cliented',
                      });
                      ReactGA4.event('redirect-to-cliented');
                    }
                    if (item.name === 'Sofie Chat') {
                      history.push('/sofie-chat');
                    }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color: isActive ? 'secondary.main' : grey[500],
                      minWidth: '47px',
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    primaryTypographyProps={{
                      sx: primaryTypographyStyle
                    }}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
            pb: 3.5,
            pl: 4.25,
            pr: 5.5,
          }}
        >
          <Button
            variant="outlined"
            color="white"
            endIcon={<LogoutIcon />}
            onClick={() => {
              history.push('/logout');
            }}
            sx={{
              fontSize: '1.1rem',
            }}
          >
            Logout
          </Button>
        </Box>
        <Box
          sx={{
            pb: 5.75,
            pl: 3.25,
            pr: 6.5,
          }}
        >
          <img
            src={Logo}
            alt="LifeLearn Company Logo"
          />
        </Box>
      </Drawer>
    </Box>
  );
};

Header.propTypes = {
  drawerWidth: PropTypes.number.isRequired,
  currentQuestion: PropTypes.string,
  currentCategory: PropTypes.string,
  name: PropTypes.string.isRequired,
};

Header.defaultProps = {
  currentQuestion: '',
  currentCategory: '',
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    currentQuestion: state.searchResults.current.question,
    currentCategory: state.searchResults.current.category,
    name: state.user.firstName,
  };
}

export default connect(mapStateToProps)(Header);
