import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
  Typography,
  Grid,
  Box,
  Pagination,
  PaginationItem,
  Tooltip,
  IconButton,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import { tooltipClasses } from '@mui/material/Tooltip';
import { grey } from '@mui/material/colors';
import { styled, useTheme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import useMediaQuery from '@mui/material/useMediaQuery';
import { closeFavoriteConfirmationModal, getFavouriteList, removeFavourite } from '../../actions/addFavourite/addFavourite';
import LoadingPage from '../../components/LoadingPage/LoadingPage';
import AskAnswer from '../../components/AskAnswer/AskAnswer';
import ShareArticleModal from '../../components/Modals/ShareArticleModal/ShareArticleModal';
import AuthRequest from '../../api/AuthRequest';
import Notification from '../../components/Notification/Notification';
import FavoriteRemovalModal from '../../components/Modals/FavoriteRemovalModal/FavoriteRemovalModal';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: grey[600],
    color: theme.palette.common.white,
    boxShadow: theme.shadows[5],
    fontSize: '1rem',
    fontWeight: 'bold',
    padding: '16px',
    borderRadius: '16px',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: grey[600],
  },
}));

const Favorite = (props) => {
  const {
    favorites,
    favoriteData,
    dispatchGetFavoriteList,
    favoritesLoading,
    modalOpen,
    modalTitle,
    modalId,
    dispatchRemoveFavourite,
    dispatchCloseFavoriteConfirmationModal,
  } = props;
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get('page') || '1', 10);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [favoriteCount, setFavoriteCount] = useState(10);
  const [shareAtricleModalOpen, setShareAtricleModalOpen] = useState(false);
  const [shareArticleModalDetails, setShareAtricleModalDetails] = useState({
    title: '',
    subtitle: '',
    docId: '',
    publisher: '',
    link: '',
  });

  useEffect(() => {
    let queryApi = false;
    if (favoriteData.length > 0) {
      favorites.forEach((favorite) => {
        const isDataLoaded = favoriteData.find((data) => data.doc_id === favorite);
        if (isDataLoaded === undefined) {
          queryApi = true;
        }
      });
    } else {
      queryApi = true;
    }

    if (queryApi) {
      dispatchGetFavoriteList();
    }
  }, []);

  const handleOpenShareArticles = (title, subtitle, docId, publisher, link) => {
    setShareAtricleModalOpen(true);
    setShareAtricleModalDetails({
      title,
      subtitle,
      docId,
      publisher,
      link,
    });
  };

  const handleCloseShareArticles = (
    reason,
    id = null,
    emails = null,
    message = null,
    title = null,
    subtitle = null,
    callback
  ) => {
    switch (reason) {
      case 'cancel':
        setShareAtricleModalOpen(false);
        break;
      case 'send':
        AuthRequest({
          method: 'post',
          url: 'share-article',
          data: {
            email: emails,
            body: message,
            title,
            subtitle,
            doc_id: id,
          },
        }).then(() => {
          setShareAtricleModalOpen(false);
          callback();
          ReactGA.event({
            category: 'article-view',
            action: 'Share',
          });
          ReactGA4.event('share-article');
          /* toast.success(
            <Notification
              title="Article Shared to Specified Recipients"
              body=""
            />
          ); */
        });
        break;
      default:
        setShareAtricleModalOpen(false);
        break;
    }
  };

  if (favoritesLoading) {
    return <LoadingPage title="Loading favorites data" />;
  }

  let numberPages = 0;
  let favoritesDisplay = <Typography>No Favorites To Display</Typography>;
  if (favoriteData.length > 0) {
    numberPages = Math.ceil(favoriteData.length / favoriteCount);

    const pageAdjustment = page * favoriteCount;
    const thisPage = favoriteData.slice(pageAdjustment - favoriteCount, pageAdjustment);

    favoritesDisplay = thisPage.map((answer, index) => {
      let topNotice;
      let information;
      let disabled;
      let url;

      switch (answer.status) {
        case 2:
          topNotice = 'This article no longer exists. Click the title to search for similar articles.';
          information = '';
          disabled = true;
          url = `/ask/2/${answer.title}`;
          break;
        case 1:
          topNotice = '';
          information = 'This favorite has been updated to the latest version of this article';
          disabled = false;
          url = `/favorite-articles/${answer.doc_id}`;
          break;
        default:
          topNotice = '';
          information = '';
          disabled = false;
          url = `/favorite-articles/${answer.doc_id}`;
          break;
      }
      return (
        <Box
          key={answer.doc_id}
          sx={{
            display: 'flex',
          }}
        >
          {information && !isMobile ? (
            <Box pt={4}>
              <LightTooltip
                disableFocusListener
                title={information}
                arrow
              >
                <IconButton aria-label={information}>
                  <InfoIcon color="success" />
                </IconButton>
              </LightTooltip>
            </Box>
          ) : (
            <Box
              sx={{
                minWidth: '32px',
                display: { xs: 'none', lg: 'block' }
              }}
            />
          )}
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <AskAnswer
              id={answer.doc_id}
              title={answer.title}
              book={answer.bookInfo[0].book}
              publisher={answer.bookInfo[0].publisher_name}
              year={answer.bookInfo[0].year}
              link={answer.bookInfo[0].website}
              body={answer.text}
              openShareArticles={handleOpenShareArticles}
              showCheckbox={false}
              topNotice={topNotice}
              disabled={disabled}
              buttonsPosition={isMobile ? 'title' : 'author'}
              information={information}
              popupRequired
              first={index === 0}
              url={url}
            />
            <Box
              sx={{
                borderBottom: '1px solid',
                mx: 2.5,
              }}
            />
          </Box>
        </Box>
      );
    });
  }

  return (
    <>
      <Grid container>
        <Grid
          item
          xs={1}
          sx={{
            display: { xs: 'none', lg: 'block' },
          }}
        />
        <Grid
          item
          xs={12}
          lg={10}
        >
          <Typography
            sx={{
              fontSize: { xs: '2.1875rem', lg: '2.8125rem' },
              fontWeight: 'bold',
              pl: 4,
              mb: { xs: 0, lg: 3 },
            }}
          >
            My Favorites
          </Typography>
          {favoritesDisplay}
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', lg: 'flex-end' },
              alignItems: 'center',
              mt: 7,
              pl: { xs: 4, lg: 0 },
            }}
          >
            <FormControl
              sx={{
                mr: 4,
              }}
            >
              <Select
                value={favoriteCount}
                onChange={(e) => {
                  setFavoriteCount(e.target.value);
                }}
                sx={{
                  '& legend': {
                    width: 'auto',
                  },
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={40}>40</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </FormControl>
            <Pagination
              count={numberPages}
              shape="rounded"
              color="testColor"
              page={page}
              renderItem={(item) => (
                <PaginationItem
                  component={Link}
                  to={`/favorites${item.page === 1 ? '' : `?page=${item.page}`}`}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...item}
                />
              )}
            />
          </Box>
        </Grid>
      </Grid>
      <ShareArticleModal
        open={shareAtricleModalOpen}
        title={shareArticleModalDetails.title}
        subtitle={shareArticleModalDetails.subtitle}
        publisher={shareArticleModalDetails.publisher}
        link={shareArticleModalDetails.link}
        handleClose={handleCloseShareArticles}
        id={shareArticleModalDetails.docId}
      />
      <FavoriteRemovalModal
        open={modalOpen}
        title={modalTitle}
        id={modalId}
        onCancel={dispatchCloseFavoriteConfirmationModal}
        onConfirm={dispatchRemoveFavourite}
      />
    </>
  );
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{ user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    searchResults: state.searchResults.current,
    favorites: state.user.favorites,
    favoriteData: state.favouriteLists.currentList.message.answers,
    favoritesLoading: state.favouriteLists.loading,
    modalOpen: state.favouriteLists.modal.open,
    modalTitle: state.favouriteLists.modal.title,
    modalId: state.favouriteLists.modal.id,
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{askQuestion: *, check: *, uncheck: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchGetFavoriteList: getFavouriteList,
      dispatchCloseFavoriteConfirmationModal: closeFavoriteConfirmationModal,
      dispatchRemoveFavourite: removeFavourite,
    },
    dispatch
  );
}

Favorite.propTypes = {
  favorites: PropTypes.arrayOf(PropTypes.string).isRequired,
  favoriteData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dispatchGetFavoriteList: PropTypes.func.isRequired,
  dispatchRemoveFavourite: PropTypes.func.isRequired,
  dispatchCloseFavoriteConfirmationModal: PropTypes.func.isRequired,
  favoritesLoading: PropTypes.bool.isRequired,
  modalOpen: PropTypes.bool.isRequired,
  modalId: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Favorite);
