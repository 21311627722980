import React from 'react';
import { createSvgIcon } from '@mui/material';

const ThreeSvg = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28">
    <defs>
      <clipPath id="clip-Artboard_3">
        <rect width="28" height="28" />
      </clipPath>
    </defs>
    <g id="Artboard_3" data-name="Artboard – 3" clipPath="url(#clip-Artboard_3)">
      <text id="_3" data-name="3" transform="translate(10 19.627)" fill="currentColor" fontSize="14" fontFamily="Poppins-Regular, Poppins" fontWeight="bold"><tspan x="0" y="0">3</tspan></text>
    </g>
  </svg>
);

export default ThreeSvg;
