import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import readNDJSONStream from 'ndjson-readablestream';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { GenerateGuid } from '../../helpers/SofieChat/utilities';
import { chatAPI } from '../../api/SofieChat/ChatAppRequest';
import ChatSection from '../../components/SofieChat/ChatSection/ChatSection';
import { getChatHistories } from '../../actions/chatHistory/chatHistoryActions';

const SofieChat = (props) => {
  const {
    chatHistoryChats,
    dispatchGetChatHistories
  } = props;

  const { chatId } = useParams();
  let isNewChat = true;
  let messages = [];
  if (chatId && (chatHistoryChats.length > 0)) {
    chatHistoryChats.forEach((chatHistoryChat) => {
      if (chatHistoryChat.chat_id === chatId) {
        isNewChat = false;
        messages = chatHistoryChat.conversation;
      }
    });
  }
  const [isNewPage, toggleNew] = useState(isNewChat);
  const [isTextfieldDisabled, setTextfieldDisabled] = useState(false);

  const lastQuestionRef = useRef('');
  const chatMessageStreamEnd = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isStreaming, setIsStreaming] = useState(false);
  const [isEmergency, setIsEmergency] = useState(false);
  const [error, setError] = useState(undefined);
  const [guid, setGuid] = useState(chatId || GenerateGuid());

  const [answers, setAnswers] = useState(messages);
  const [streamedAnswers, setStreamedAnswers] = useState([]);
  const [answerCompleted, setAnswerCompleted] = useState(false);

  const onClickNewChat = () => {
    // Reset the state variables to create a 'new' page
    toggleNew(true);
    setTextfieldDisabled(false);
    setIsLoading(false);
    setIsStreaming(false);
    setIsEmergency(false);
    setError(undefined);
    setGuid(GenerateGuid());
    setAnswers([]);
    setStreamedAnswers([]);
    setAnswerCompleted(false);
  };

  useEffect(() => {
    if (chatId === undefined) {
      onClickNewChat();
    }
  }, [chatId]);

  /**
   * handles a request asynch
   * @param {string} question
   * @param {Array(string | ChatAppResponse)} answers
   * @param {Function} setAnswers
   * @param {RedableStream<any>} responseBody
   * @returns the full response
   */

  const handleAsyncRequest = async (question, responseBody) => {
    let answer = '';
    let askResponse = {};

    const updateState = (newContent) => new Promise((resolve) => {
      setTimeout(() => {
        answer += newContent;
        const latestResponse = {
          ...askResponse,
          choices: [
            {
              ...askResponse.choices[0],
              message: {
                content: answer,
                role: askResponse.choices[0].message.role,
              },

            },
          ],
        };
        setStreamedAnswers([...answers, [question, latestResponse]]);
        resolve(null);
      }, 33);
    });
    try {
      setIsStreaming(true);
      // eslint-disable-next-line no-restricted-syntax
      for await (const event of readNDJSONStream(responseBody)) {
        if (event.choices && event.choices[0].context && event.choices[0].context.thoughts) {
          askResponse = event;
        } else if (event.choices && event.choices[0].message.content) {
          setIsLoading(false);
          await updateState(event.choices[0].message.content);
        } else if (event.choices && event.choices[0].context) {
          // Update context with new keys from latest event
          askResponse.choices[0].context = {
            ...askResponse.choices[0].context,
            ...event.choices[0].context,
          };
        } else if (event.error) {
          throw Error(event.error);
        }
      }
    } finally {
      // setIsStreaming(false);
    }
    const fullResponse = {
      ...askResponse,
      choices: [
        {
          ...askResponse.choices[0],
          message: {
            content: answer,
            role: askResponse.choices[0].message.role,
          },
          sources: askResponse.choices[0].sources
        },
      ],

    };

    return fullResponse;
  };

  /*
   * function to make an API req
   * @param {string} question - some question to the API
   */
  const makeApiRequest = async (question) => {
    setAnswerCompleted(false);
    lastQuestionRef.current = question;

    if (error) setError(undefined);
    setIsLoading(true);

    try {
      const promptResponsePair = answers.flatMap((a) => [
        { content: a[0], role: 'user' },
        {
          content: a[1].choices[0].message.content,
          role: 'assistant',
          sources: a[1].choices[0].sources,
        },
      ]);
      const request = {
        messages: [...promptResponsePair, { content: question, role: 'user' }],
        stream: true,
        chat_id: guid,
        save_chat: true,
      };
      const response = await chatAPI(request);
      if (!response.body) {
        throw Error('No response body');
      }
      const parsedResponse = await handleAsyncRequest(question, response.body);
      const emergStatus = parsedResponse.choices[0].context.is_emergency !== undefined
        && parsedResponse.choices[0].context.is_emergency === true;
      setIsEmergency(emergStatus);

      /* saveToLog(question, chatType, parsedResponse); */
      setAnswers([...answers, [question, parsedResponse]]);
      setAnswerCompleted(true);
    } catch (e) {
      setError(e);
    } finally {
      setIsLoading(false);
      setTextfieldDisabled(false);
      dispatchGetChatHistories();
    }
  };

  // This causes the browser to scroll to the end of the the chat input.
  useEffect(
    () => chatMessageStreamEnd.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    }),
    [isLoading]
  );
  useEffect(
    () => chatMessageStreamEnd.current?.scrollIntoView({
      behavior: 'auto',
      block: 'nearest',
      inline: 'start',
    }),
    [streamedAnswers]
  );

  // eslint-disable-next-line no-unused-vars
  const onClickMic = () => {
    // alert('Mic button pressed');
  };

  const onClickArrow = (chatValue) => {
    if (isNewPage) {
      toggleNew(false);
    }
    setTextfieldDisabled(true);
    makeApiRequest(chatValue);
  };

  return (
    <ChatSection
      answerCompleted={answerCompleted}
      answers={answers}
      chatMessageStreamEnd={chatMessageStreamEnd}
      error={error}
      isEmergency={isEmergency}
      isLoading={isLoading}
      isNewPage={isNewPage}
      isStreaming={isStreaming}
      isTextfieldDisabled={isTextfieldDisabled}
      lastQuestion={lastQuestionRef.current}
      makeApiRequest={makeApiRequest}
      onClickArrow={onClickArrow}
      onClickNewChat={onClickNewChat}
      onClickMic={onClickMic}
      streamedAnswers={streamedAnswers}
      toggleSuggestions={toggleNew}
    />
  );
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps chatHistoryReducer from redux to apps props
 */
function mapStateToProps(state) {
  return {
    /* loading: state.chatHistoryReducer.loading, */
    chatHistoryChats: state.chatHistoryReducer.chatHistories,
    /* errorLoad: state.chatHistoryReducer.errorLoad,
    errorDelete: state.chatHistoryReducer.errorDelete */
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux
 * @returns - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchGetChatHistories: getChatHistories
    },
    dispatch
  );
}

SofieChat.propTypes = {
  chatHistoryChats: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      chat_id: PropTypes.string,
      start_time: PropTypes.string,
      end_time: PropTypes.string,
      topic: PropTypes.string,
      conversation: PropTypes.arrayOf(
        PropTypes.shape({
          choices: PropTypes.arrayOf(
            PropTypes.shape({
              content: PropTypes.string,
              role: PropTypes.oneOf(['user', 'assistant', 'root']),
            })
          ),
        })
      ),
      user_id: PropTypes.number,
    })
  ).isRequired,
  dispatchGetChatHistories: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(SofieChat);
