import React from 'react';
import {
  Box, Link, Button
} from '@mui/material';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import Logo from '../Logo/Logo';

const MobileHeader = () => {
  const history = useHistory();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pl: 2,
        pr: 3,
      }}
    >
      <Link
        component={RouterLink}
        to="/sofie-chat"
        sx={{
          textDecoration: 'none',
          color: 'lightBlue.main',
          '&:hover, &:active': {
            textDecoration: 'none',
            color: 'lightBlue.main',
          },
        }}
      >
        <Logo />
      </Link>
      <Box>
        <Button
          variant="outlined"
          color="darkGrey"
          endIcon={<LogoutIcon />}
          onClick={() => {
            history.push('/logout');
          }}
          sx={{
            fontSize: '1rem',
          }}
        >
          Logout
        </Button>
      </Box>
    </Box>
  );
};

export default MobileHeader;
