/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Typography, Box, Link, Icon, Button, Grid, useMediaQuery
} from '@mui/material';

// import StarOutlineIcon from '@mui/icons-material/StarOutline';
// import StarIcon from '@mui/icons-material/Star';
// import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CloseIcon from '@mui/icons-material/Close';
import useTheme from '@mui/material/styles/useTheme';
import { ResponseMessageProp } from '../../../../api/SofieChat/PropTypes';

const ChatHistoryLink = (props) => {
  const {
    titleProp, lastEditDate, color, isFavoritedProp, openModal, chatId
  } = props;
  /* const [isFavorited, setIsFavorited] = useState(isFavoritedProp); */

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const rgbString = color === 'grey'
    ? '#FFFFFF26'
    : theme.palette.text.main;

  return (
    <Grid
      container
      sx={{
        alignItems: 'center',
        background: rgbString,
        borderRadius: '6px'
      }}

    >
      <Grid
        item
        xs={0.5}
      >
        {/* <Box pr="1%">
          <Button
            onClick={() => {
              const tempFav = !isFavorited;
              setIsFavorited(tempFav);
            }}
          >
            <Icon
              color="secondary"
              sx={{
                height: 'auto',
                width: '100%',
              }}
            >
              {isFavorited ? (
                <StarIcon sx={{ fontSize: 'x-large' }} />
              ) : (
                <StarOutlineIcon sx={{ fontSize: 'x-large' }} />
              )}
            </Icon>
          </Button>
        </Box> */}
      </Grid>
      <Grid
        item
        xs={1.5}
      >
        <Box
          sx={{
            borderStyle: !isMobile ? 'none solid none none' : '',
            borderWidth: !isMobile ? 'thin' : '',
            borderColor: !isMobile ? '#FFFFFF' : '',
            paddingRight: !isMobile ? '2%' : ''
          }}
        >
          <Typography
            variant="body1"
            component="span"
            sx={{ color: '#FFFFFF' }}
          >
            {lastEditDate}
          </Typography>
        </Box>
      </Grid>
      <Grid
        item
        xs={8}
        md={9}
      >
        <Box sx={{ paddingLeft: '2%' }}>
          <Link
            to={`/sofie-chat/${chatId}`}
            component={RouterLink}
            underline="none"
            sx={{
              color: '#FFFFFF',
              fontWeight: 'bold',
              cursor: 'pointer',
              '&:hover': {
                color: 'secondary.main'
              }
            }}
          >
            {titleProp}
          </Link>
        </Box>
      </Grid>
      <Grid
        item
        xs={2}
        md={1}
        display="flex"
      >
        <Box display="flex">
          <Button
            sx={{
              ':focus': {
                outline: 'none', // Remove the focus outline
              },
            }}
          >
            <Icon
              color="secondary"
              sx={{
                height: 'auto',
                width: '100%',
              }}
            >
              <CloseIcon
                onClick={openModal}
                sx={{ fontSize: 'x-large' }}
              />
            </Icon>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

ChatHistoryLink.propTypes = {
  titleProp: PropTypes.string.isRequired,
  lastEditDate: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['blue', 'grey']).isRequired,
  isFavoritedProp: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  chatId: PropTypes.string.isRequired,
};
ChatHistoryLink.defaultProps = {};

export default ChatHistoryLink;
