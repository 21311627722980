import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Typography, dividerClasses
} from '@mui/material';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ResponseSourceProp } from '../../../../api/SofieChat/PropTypes';

/* import { getCitationFilePath } from '../../api';
import Citation from './Citation';
import { ContactBubble } from './ContactBubble'; */
import Citation from '../Citation/Citation';
import { formatInnerHtml } from '../../../../helpers/SofieChat/utilities';

const AssistantBubble = (props) => {
  const {
    children,
    innerHtml,
    type,
    showIcon,
    related,
    anchorIdentifier,
    isIntro
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const formattedInnerHtml = formatInnerHtml(innerHtml);

  return (
    <>
      <Box
        sx={{
          mb: 2,
          display: 'flex',
          alignItems: 'flex-end',
          borderColor: 'divider',
          '& svg': {
            m: 1,
          },
          [`& .${dividerClasses.root}`]: {
            mx: 0.5,
          },
          width: isSmallScreen ? '100%' : 'auto'
        }}
      >
        {showIcon ? (
          <Box
            sx={{
              width: '40px',
              height: '40px',
              ml: 2.625,

            }}
          >
            <ChatBubbleOutlineRoundedIcon color="secondary" />
          </Box>
        ) : <Box ml={isIntro ? '0px' : '61px'} />}
        <Box
          sx={{
            backgroundColor: '#E8F0F3',
            borderRadius: type === 'end' ? '0px 0px 10px 0px' : '10px 10px 10px 0px',
            boxShadow: '0px 3px 5px #001C2F0A',
            p: 3,
            maxWidth: isIntro && isMobile ? 'none' : '60%', // Set max width
            width: isIntro && isMobile ? '100%' : 'fit-content', // Adjust width to content
            display: 'inline-block'
          }}
        >
          {children && (
            <Typography
              sx={{
                color: '#6B6B6B',
                display: 'inline-block',
                fontSize: '1.125rem',
              }}
            >
              {children}
            </Typography>
          )}
          {innerHtml && (
            <Box
              sx={{
                color: '#6B6B6B',
                display: 'inline-block',
                fontSize: '1.125rem',
                fontFamily: 'proxima-nova'
              }}
            >
              <Box
                sx={{
                  '& sup': {
                    position: 'relative',
                    display: 'inline-flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '10px',
                    fontWeight: 600,
                    verticalAlign: 'top',
                    top: '-1px',
                    margin: '0px 2px',
                    minWidth: '14px',
                    height: '14px',
                    borderRadius: '50%',
                    background: '#f8992d',
                    color: '#ffffff',
                    textDecorationColor: 'transparent',
                    outline: 'transparent solid 1px',
                    cursor: 'pointer'
                  }
                }}
                dangerouslySetInnerHTML={{ __html: formattedInnerHtml }}
              />
            </Box>
          )}
        </Box>
      </Box>
      {related && related.length > 0 && (
        <Box
          display="flex"
          sx={{
            ml: 8.625,
            mb: 2.625,
            maxWidth: '100%', // Set to 100% to utilize full width
            flexWrap: 'wrap', // Allow wrapping if necessary
            gap: 1, // Optional: add space between citations
          }}
          id={anchorIdentifier}
        >
          <Typography
            sx={{
              display: 'inline-block',
              color: '#022740',
              fontSize: '0.9675rem',
              fontWeight: 'normal',
              mr: 1.25,
              mt: 0.80,
            }}
          >
            Learn More:
            {' '}
          </Typography>
          {related.map((element, index) => (
            <Citation
              key={element.id}
              title={element.title}
              titleNumber={index + 1}
              link={`${APP_URL}/view-articles/${element.id}`}
              summary={element.summary}
            />
          ))}
        </Box>
      )}
    </>
  );
};

AssistantBubble.propTypes = {
  children: PropTypes.node,
  innerHtml: PropTypes.string,
  type: PropTypes.oneOf(['end', 'regular']),
  showIcon: PropTypes.bool,
  citations: PropTypes.arrayOf(PropTypes.string),
  isEmergency: PropTypes.bool,
  related: PropTypes.arrayOf(ResponseSourceProp),
  anchorIdentifier: PropTypes.string,
  isIntro: PropTypes.bool
};

AssistantBubble.defaultProps = {
  children: null,
  innerHtml: '',
  type: 'end',
  showIcon: true,
  citations: [],
  isEmergency: false,
  related: [],
  anchorIdentifier: '',
  isIntro: false
};

export default AssistantBubble;
