/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

export const ChatAppRequestOverridesProp = PropTypes.shape({
  semantic_ranker: PropTypes.bool,
  semantic_captions: PropTypes.bool,
  exclude_category: PropTypes.string,
  top: PropTypes.number,
  temperature: PropTypes.number,
  minimum_search_score: PropTypes.number,
  minimum_reranker_score: PropTypes.number,
  prompt_template: PropTypes.string,
  prompt_template_prefix: PropTypes.string,
  prompt_template_suffix: PropTypes.string,
  suggest_followup_questions: PropTypes.bool,
  use_oid_security_filter: PropTypes.bool,
  use_groups_security_filter: PropTypes.bool

});

export const ResponseMessageProp = PropTypes.shape({
  content: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
});

export const ThoughtsProp = PropTypes.shape({
  title: PropTypes.string.isRequired,
  description: PropTypes.any.isRequired,
  props: PropTypes.oneOfType([PropTypes.object, PropTypes.null])
});

export const ResponseContextProp = PropTypes.shape({
  data_points: PropTypes.arrayOf(PropTypes.string).isRequired,
  followup_questions: PropTypes.arrayOf(PropTypes.string).isRequired,
  thoughts: ThoughtsProp.isRequired,
  is_emergency: PropTypes.bool.isRequired,
  contact_vet: PropTypes.bool.isRequired,
});

export const ResponseChoiceProp = PropTypes.shape({
  index: PropTypes.number.isRequired,
  message: ResponseMessageProp.isRequired,
  context: ResponseContextProp.isRequired,
  session_state: PropTypes.any.isRequired,
  sources: PropTypes.any.isRequired
});

export const ChatAppResponseOrErrorProp = PropTypes.shape({
  choices: PropTypes.arrayOf(ResponseChoiceProp),
  error: PropTypes.string
});

export const ResponseSourceProp = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
  featured_image: PropTypes.string.isRequired,

});

export const InfoAppResponseOrErrorProp = PropTypes.shape({
  model: PropTypes.string,
  index: PropTypes.string,
  error: PropTypes.string,
  indices: PropTypes.any
});

export const InfoAppResponseProp = PropTypes.shape({
  model: PropTypes.string.isRequired,
  index: PropTypes.any.isRequired,
  questions: PropTypes.arrayOf(PropTypes.string).isRequired,
  indices: PropTypes.any.isRequired,
});

export const ChatAppResponseProp = PropTypes.shape({
  choices: PropTypes.arrayOf(ResponseChoiceProp).isRequired
});

export const ChatAppSourceProp = PropTypes.shape({
  sources: PropTypes.arrayOf(ResponseSourceProp).isRequired
});

export const ChatAppRequestContextProp = PropTypes.shape({
  overrides: ChatAppRequestOverridesProp
});

export const ChatAppRequestProp = PropTypes.shape({
  messages: PropTypes.arrayOf(ResponseMessageProp).isRequired,
  chat_type: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  stream: PropTypes.bool,
  session_state: PropTypes.any
});

export const SaveAppRequestProp = PropTypes.shape({
  user: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  medical_accuracy: PropTypes.number.isRequired,
  readability: PropTypes.number.isRequired,
  completeness_of_answer: PropTypes.number.isRequired,
  score_explanation: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  edited_answer: PropTypes.string.isRequired,
  model: PropTypes.string.isRequired,
  index: PropTypes.string.isRequired,
  document_ids: PropTypes.arrayOf(PropTypes.number).isRequired
});

export const SaveLogRequestProp = PropTypes.shape({
  start: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  owner_id: PropTypes.number.isRequired,
  chat_type: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  sources: PropTypes.arrayOf(PropTypes.object).isRequired,
  tokens_input: PropTypes.number.isRequired,
  tokens_output: PropTypes.number.isRequired,
  model: PropTypes.string.isRequired,
  prompt: PropTypes.string.isRequired,
  guid: PropTypes.string.isRequired,
  contact_email: PropTypes.string.isRequired,
  ip_address: PropTypes.string.isRequired,
  user_agent: PropTypes.string.isRequired
});

export const ConfigProp = PropTypes.shape({
  showGPT4VOptions: PropTypes.bool.isRequired,
  showSemanticRankerOption: PropTypes.bool.isRequired,
  showVectorOption: PropTypes.bool.isRequired
});

export const ChatPlatformConfigProp = PropTypes.shape({
  valid: PropTypes.bool.isRequired,
  platform: PropTypes.any.isRequired,
  clientId: PropTypes.any.isRequired,
  libraryUrl: PropTypes.any.isRequired
});

export const PrimaryClinicDataProp = PropTypes.shape({
  email: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  zip: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  hours: PropTypes.string.isRequired,
  library_link: PropTypes.string.isRequired,
  appt_link: PropTypes.string.isRequired,
  booking_link: PropTypes.string.isRequired,
});

export const AfterHoursProp = PropTypes.shape({
  name: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  hours: PropTypes.string.isRequired,
});

export const CustomContextModelProp = PropTypes.shape({
  acceptedDisclaimer: PropTypes.bool.isRequired,
  setAcceptedDisclaimer: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  widgetType: PropTypes.string.isRequired,
  practiceInfo: PrimaryClinicDataProp.isRequired,
  afterHoursInfo: AfterHoursProp.isRequired,
});

export const AccountInfoRequestProp = PropTypes.shape({
  account_id: PropTypes.number.isRequired,
  stream: PropTypes.bool
});

export const AnswerArticleProp = PropTypes.shape({
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired
});

export const TranscriptRequestProp = PropTypes.shape({
  name: PropTypes.string.isRequired,
  contact_number: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  guid: PropTypes.string.isRequired
});

export const RetrievalModeProp = PropTypes.oneOf(['hybrid', 'vectors', 'text']).isRequired;

export const SelectedIndexProp = PropTypes.oneOf(['client-ed-index-bge-chunked', 'sofie-index']).isRequired;

export const GPT4VInputProp = PropTypes.oneOf(['textAndImages', 'images', 'text']).isRequired;

export const VectorFieldOptionsProp = PropTypes.oneOf(['contentVector', 'titleVector', 'both']).isRequired;

export const ScoresProp = PropTypes.shape({
  question: PropTypes.string.isRequired,
  answer: PropTypes.string,
  document_ids: PropTypes.arrayOf(PropTypes.number),
  model: PropTypes.string.isRequired,
  medical_accuracy: PropTypes.number.isRequired,
  completeness: PropTypes.string.isRequired,
  readability: PropTypes.string.isRequired,
  score: PropTypes.string.isRequired,
  explanation: PropTypes.string.isRequired,
  created_at: PropTypes.instanceOf(Date).isRequired
});

export const AllyLogsProp = PropTypes.shape({
  id: PropTypes.number.isRequired,
  user: PropTypes.string.isRequired,
  created_at: PropTypes.instanceOf(Date).isRequired,
  endpoint: PropTypes.string.isRequired
});

export const DataResponseOrError = PropTypes.shape({
  data: PropTypes.oneOfType([PropTypes.arrayOf(ScoresProp), PropTypes.arrayOf(AllyLogsProp)]),
});

export const ChatProps = PropTypes.shape({
  clientId: PropTypes.number,
  widgetType: PropTypes.string,
  libraryUrl: PropTypes.string
});

export const AnswersProps = PropTypes.arrayOf(
  PropTypes.shape({
    user: PropTypes.string,
    resopnse: ChatAppResponseProp
  })
);

/** May need to rename the const */
/*
export const ChatAppRequestOverridesProp = PropTypes.shape({
  retrieval_mode: RetrievalModeProp,
    selected_index: SelectedIndexProp,
    semantic_ranker: PropTypes.bool,
    semantic_captions: PropTypes.bool,
    exclude_category: PropTypes.string,
    top: PropTypes.number,
    temperature: PropTypes.number,
    minimum_search_score: PropTypes.number,
    minimum_reranker_score: PropTypes.number,
    prompt_template: PropTypes.number,
    prompt_template_prefix: PropTypes.number,
    prompt_template_suffix: PropTypes.number,
    suggest_followup_questions: PropTypes.bool,
    use_oid_security_filter: PropTypes.bool,
    use_groups_security_filter: PropTypes.bool,
    use_gpt4v: PropTypes.bool,
    gpt4v_input: GPT4VInputProp,
    vector_fields: PropTypes.arrayOf(VectorFieldOptionsProp)
}); */
