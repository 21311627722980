/* eslint-disable react/display-name */
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
  Divider,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ChatHistorySection = (props) => {
  const { title, children, defaultopen } = props;
  console.log('defaultOpen =', defaultopen);
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.text.primary,
        paddingLeft: {
          xs: '0px',
          sm: '0px',
          md: '100px',
          lg: '100px',
        },
        paddingRight: {
          xs: '0px',
          sm: '0px',
          md: '150px',
          lg: '150px',
        },
      }}
    >
      <Accordion
        sx={{
          background: 'transparent !important',
          color: 'white.main',
          paddingLeft: '3%',
          paddingRight: '3%',
        }}
        defaultExpanded={defaultopen}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: theme.palette.secondary.main, fontSize: 'xxx-large' }} />}
          onClick={localStorage.setItem('chatHistoryAccordob', title)}
        >
          <Box
            display="flex"
            width="100%"
            alignItems="center"
            gap={2}
          >
            <Typography component="span" fontWeight={500} fontSize="large" color={theme.palette.white.main}>{title}</Typography>
            <Divider
              sx={{
                flexGrow: '1'
              }}
              style={{
                borderBottomColor: theme.palette.white.main,
                borderStyle: 'solid',
                borderBottom: 'solid',
                borderBottomWidth: 'thin',
                height: 'auto',
                position: 'relative',
                width: {
                  xs: '0%',
                  sm: '0%',
                  md: '80%',
                  lg: '95%',
                },
              }}
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Box>
  );
};

ChatHistorySection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  defaultopen: PropTypes.bool
};

ChatHistorySection.defaultProps = {
  children: null,
  defaultopen: false
};

export default ChatHistorySection;
