import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PropTypes from 'prop-types';

const UserChatMessage = (props) => {
  const { message } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        borderRight: '1px solid #CCCCCC',
        marginBottom: '1rem',
      }}
      elevation={5}
    >
      <Typography
        sx={{
          maxWidth: '60%',
          border: '1px solid #CCCCCC;',
          borderRadius: '10px 10px 0px 10px;',
          p: 3,
          color: '#022740',
          fontSize: '1.0625rem',
        }}
      >
        {message}
      </Typography>
      <Box
        sx={{
          width: '40px',
          height: '40px',
          ml: 2.625,
          overflow: 'hidden',
        }}
      >
        <AccountCircleIcon />
      </Box>
      <Box
        sx={{
          width: '27px',
          height: '27px',
          mr: isMobile ? 0 : 2.625,
          ml: isMobile ? 0 : 1.625,
          color: '#474D50',
        }}
      />
    </Box>
  );
};

UserChatMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

UserChatMessage.defaultProps = {};

export default UserChatMessage;
