/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Box, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ChatBubbleOutlineRounded from '@mui/icons-material/ChatBubbleOutlineOutlined';

import UserChatMessage from '../SpeechBubble/UserChatMessage/UserChatMessage';
import Answer from '../Answer/Answer';
import { AnswersProps } from '../../../api/SofieChat/PropTypes';
import AssistantBubble from '../SpeechBubble/AssistantChatMessage/AssistantChatMessage';
import LoadingDots from '../LoadingDots/LoadingDots';
import ChatControls from '../ChatControls/ChatControls';
import IntroMessage from '../IntroMessage/IntroMessage';
import AnswerError from '../Answer/AnswerError';

const ChatSection = ({
  answerCompleted,
  answers,
  chatMessageStreamEnd,
  error,
  isStreaming,
  isLoading,
  isEmergency,
  isNewPage,
  isTextfieldDisabled,
  lastQuestion,
  makeApiRequest,
  onClickArrow,
  onClickNewChat,
  onClickMic,
  streamedAnswers,
  toggleSuggestions
}) => {
  const messagesEndRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const isTabletSize = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));
  let introContainerWidth = null;
  if (isTabletSize) introContainerWidth = '50%';
  if (isMobileSize) introContainerWidth = '80%';

  // Scroll to the bottom whenever the messages array changes
  useEffect(() => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [answers, streamedAnswers]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        overflowY: 'scroll',
        height: isMobile ? 'calc(100vh - 277px)' : 'calc(100vh - 88px)'
      }}
    >
      {isNewPage
        && (

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          flexGrow={1}
        >
          <IntroMessage />
          <Box
            display="flex"
            justifyContent="center"
            mt="3%"
            gap={2}
          >
            <Divider
              orientation="vertical"
              flexItem
              sx={{
                borderColor: 'rgb(0, 0, 0, 0.2)'
              }}
            />
            <Box
              display="flex"
              flexDirection="column"
              width={introContainerWidth || 'auto'}
            >
              <AssistantBubble type="regular" showIcon={false} isIntro>
                Hi! I&apos;m Sofie AI Chat, powered by Sofie.AI, the largest library of pet health content written by veterinarians.
              </AssistantBubble>
              <AssistantBubble type="end" showIcon={false} isIntro>
                What can I help you with today?
              </AssistantBubble>
            </Box>
          </Box>
        </Box>
        )}
      {!isNewPage
        && (
          <>
            <Box
              display="flex"
              flexDirection="row"
              gap={2}
              position="relative"
              top="0"
              left="0"
              marginLeft={isMobile ? '15px' : '100px'}
              marginBottom="100px"
            >
              <ChatBubbleOutlineRounded
                sx={{
                  color: 'secondary.main',
                  fontSize: '50px'
                }}
              />
              <Typography
                variant="h1"
                fontWeight="bold"
                sx={{
                  fontSize: '45px'
                }}
              >
                Sofie.AI
              </Typography>
            </Box>
            <Box flexGrow={1} ml={isMobile ? '0px' : '150px'}>
              <AssistantBubble type="regular" showIcon={false}>
                Hi! I&apos;m Sofie AI Chat, powered by Sofie.AI, the largest library of pet health content written by veterinarians.
              </AssistantBubble>
              <AssistantBubble type="end">
                What can I help you with today?
              </AssistantBubble>

              {isStreaming
                && streamedAnswers.map((streamedAnswer, index) => (
                  <div key={index}>
                    <UserChatMessage message={streamedAnswer[0]} />
                    <Answer
                      isStreaming
                      answer={streamedAnswer[1]}
                      isSelected={false}
                      isEmergency={isEmergency}
                    />
                  </div>
                ))}
              {!isStreaming
                && answers.map((answer, index) => (
                  <div key={index}>
                    <UserChatMessage message={answer[0]} />
                    <Answer
                      isStreaming={false}
                      key={index}
                      answer={answer[1]}
                      isSelected={false}
                      isEmergency={isEmergency}
                    />
                  </div>
                ))}
              {isLoading && (
                <>
                  <UserChatMessage message={lastQuestion} />
                  <AssistantBubble>
                    Loading Response
                    <LoadingDots />
                  </AssistantBubble>
                </>
              )}
              {error ? (
                <>
                  <UserChatMessage message={lastQuestion} />
                  <AnswerError
                    error={error.toString()}
                    onRetry={() => makeApiRequest(lastQuestion)}
                  />
                </>
              ) : null}
              <div ref={chatMessageStreamEnd} />
            </Box>
          </>
        )}
      <div ref={messagesEndRef} />
      <ChatControls
        onClickArrow={onClickArrow}
        onClickNewChat={onClickNewChat}
        onClickMic={onClickMic}
        isTextfieldDisabled={isTextfieldDisabled}
        isNewPage={isNewPage}
        toggleSuggestions={toggleSuggestions}
      />
    </Box>
  );
};

export default ChatSection;

ChatSection.propTypes = {
  answerCompleted: PropTypes.bool.isRequired,
  answers: AnswersProps,
  chatMessageStreamEnd: PropTypes.shape(),
  error: PropTypes.string,
  isEmergency: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isNewPage: PropTypes.bool.isRequired,
  isStreaming: PropTypes.bool.isRequired,
  isTextfieldDisabled: PropTypes.bool.isRequired,
  lastQuestion: PropTypes.string.isRequired,
  onClickNewChat: PropTypes.func.isRequired,
  onClickArrow: PropTypes.func.isRequired,
  makeApiRequest: PropTypes.func.isRequired,
  onClickMic: PropTypes.func.isRequired,
  streamedAnswers: AnswersProps,
  toggleSuggestions: PropTypes.func.isRequired
  /* libraryUrl: string,
  widgetType: string, */
};
ChatSection.defaultProps = {
  chatMessageStreamEnd: <></>,
  isEmergency: false,
  answers: [],
  error: null,
  streamedAnswers: []
};
