import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Typography, Grid
} from '@mui/material';
import { ErrorOutline } from '@mui/icons-material';
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';

import './Answer.scss';

const AnswerError = ({ error, onRetry }) => (

  <Box
    sx={{
      borderLeft: '1px solid #CCCCCC',
      mb: 2,
      display: 'flex',
      alignItems: 'flex-end',
    }}
  >

    <Box
      sx={{
        width: '40px',
        height: '40px',
        ml: 2.625,
        overflow: 'hidden',
      }}
    >
      <ChatBubbleOutlineRoundedIcon sx={{
        color: 'orange'
      }}
      />
    </Box>
    <Box
      sx={{
        backgroundColor: '#E8F0F3',
        borderRadius: '50px 50px 50px 0px',
        boxShadow: '0px 3px 5px #001C2F0A',
        p: 3,
        maxWidth: '60%',
        display: 'inline-block',
        ml: 1.625
      }}
    >
      <Box className="answerContainer" display="flex" flexDirection="column" justifyContent="space-between">
        <ErrorOutline
          aria-hidden="true"
          aria-label="Error icon"
          style={{ color: 'red', fontSize: 24 }}
        />

        <Box flexGrow={1}>
          <Typography variant="body1" className="answerText">
            {error}
          </Typography>
        </Box>

        <Button
          className="retryButton"
          onClick={onRetry}
          variant="contained"
          color="primary"
        >
          Retry
        </Button>
      </Box>
    </Box>
  </Box>
);

AnswerError.propTypes = {
  error: PropTypes.string.isRequired,
  onRetry: PropTypes.func.isRequired,
};

export default AnswerError;
