exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Imports

exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap);"]);
exports.push([module.id, "@import url(https://use.typekit.net/tzf8hks.css);"]);
exports.push([module.id, "@import url(https://use.fontawesome.com/releases/v5.7.2/css/all.css);"]);
// Module
exports.push([module.id, ".maintenancePage{display:flex;flex-flow:column nowrap;flex:1 0 auto}.maintenancePage_body{max-width:90%;align-self:center;padding-bottom:2rem;border-bottom:4px solid #ececec}\n", ""]);
// Exports
exports.locals = {
	"primaryColor": "#006281",
	"secondaryColor": "#f8992d"
};