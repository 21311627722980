/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  useMediaQuery
} from '@mui/material';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useTheme } from '@mui/material/styles';
import DeleteChatModal from '../../components/SofieChat/DeleteChatModal/DeleteChatModal';
import LoadingPage from '../../components/LoadingPage/LoadingPage';
import { deleteChat } from '../../actions/chatHistory/chatHistoryActions';
import ChatHistoryHeader from '../../components/SofieChat/ChatHistory/ChatHistoryHeader/ChatHistoryHeader';
import ChatHistorySection from '../../components/SofieChat/ChatHistory/ChatHistorySection/ChatHistorySection';
import ChatHistoryList from '../../components/SofieChat/ChatHistory/ChatHistoryList/ChatHistoryList';
import { getChatHistorySections } from '../../helpers/SofieChat/utilities';
import ChatHistoryHeaderMobile from '../../components/SofieChat/ChatHistory/ChatHistoryHeader/ChatHistoryHeaderMobile';

const ChatHistory = (props) => {
  const {
    // eslint-disable-next-line no-unused-vars
    loading, chatHistories, errorLoad, errorDelete, dispatchDeleteChat
  } = props;
  const [isOpen, setOpen] = useState(false);
  const [deleteChatId, setDeleteChatId] = useState('');
  const [deleteChatTitle, setDeleteChatTitle] = useState('');
  const [searchListOpen, setSearchListOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const handleClose = () => {
    setDeleteChatId('');
    setDeleteChatTitle('');
    setOpen(false);
  };

  const handleDelete = async () => {
    dispatchDeleteChat(deleteChatId, chatHistories);
    handleClose();
  };

  const chatHistorySections = getChatHistorySections(chatHistories);

  const chatHistorySectionComponents = chatHistorySections.map((section, index) => (
    <ChatHistorySection title={section.yearAndMonth} key={index} defaultopen={index === 0}>
      <ChatHistoryList
        chats={section.chats}
        toggleModal={setOpen}
        setDeleteChatId={setDeleteChatId}
        setDeleteChatTitle={setDeleteChatTitle}
      />
    </ChatHistorySection>
  ));

  return (
    <>
      {loading && (
      <LoadingPage title="Chat History" />
      )}
      {!loading && (
        <Box>
          {isMobile
            ? <ChatHistoryHeaderMobile chats={chatHistories} setSearchListOpen={setSearchListOpen} />
            : <ChatHistoryHeader chats={chatHistories} setSearchListOpen={setSearchListOpen} />}

          <Box>
            {chatHistorySectionComponents}
          </Box>
        </Box>
      )}

      <DeleteChatModal
        chatTitle={deleteChatTitle}
        isOpen={isOpen}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
    </>
  );
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{user: *}} - maps chatHistoryReducer from redux to apps props
 */
function mapStateToProps(state) {
  return {
    loading: state.chatHistoryReducer.loading,
    chatHistories: state.chatHistoryReducer.chatHistories,
    errorLoad: state.chatHistoryReducer.errorLoad,
    errorDelete: state.chatHistoryReducer.errorDelete
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns - bound action creators
 *
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchDeleteChat: deleteChat
    },
    dispatch
  );
}

ChatHistory.propTypes = {
  loading: PropTypes.bool.isRequired,
  chatHistories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      chat_id: PropTypes.string,
      start_time: PropTypes.string,
      end_time: PropTypes.string,
      topic: PropTypes.string,
      messages: PropTypes.arrayOf(
        PropTypes.shape({
          content: PropTypes.string,
          role: PropTypes.oneOf(['user', 'assistant', 'tool'])
        })
      ),
      user_id: PropTypes.number
    })
  ).isRequired,
  errorLoad: PropTypes.string.isRequired,
  errorDelete: PropTypes.string.isRequired,
  dispatchDeleteChat: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatHistory);
