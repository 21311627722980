/**
 * some desc
 * @returns {Record<string, string>} some headers
 */
export function getHeaders() {
  const idToken = localStorage.getItem('sofie_token');

  const headers = {
    Authorization: idToken,
    'Content-Type': 'application/json',
  };
  return headers;
}

/**
 * Make an POST request to the LLM
 * @param {ChatAppRequestProp} request - req object
 * @returns {Promise<Response>} chat response as a promise
 */
export async function chatAPI(request) {
  let endpoint = '';
  if (APP_ENV === 'staging' || APP_ENV === 'production') endpoint = `${AI_CHAT_URI}/api/v1/sofie/sofie-chat`;

  else endpoint = `${AI_CHAT_URI}/api/v1/sofie/sofie-chat-dev`;

  const response = await fetch(endpoint, {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(request),
  });
  return response;
}

/**
 * Delete a specific chat history
 * @param {string} chatId - chat conversation to delete
 * @returns {Promise<Response>} response object
 */
export async function deleteChatAPI(chatId) {
  let endpoint = '';
  if (APP_ENV === 'staging' || APP_ENV === 'production') endpoint = `${AI_CHAT_URI}/api/v1/sofie/history/`;

  else endpoint = `${AI_CHAT_URI}/api/v1/sofie/history-dev/`;

  const response = await fetch(`${endpoint}${chatId}`, {
    method: 'DELETE',
    headers: getHeaders(),
  });

  return response;
}

// export async function getChatHistoriesAPI(requestBody) {
//   let endpoint = '';

// eslint-disable-next-line max-len
//   if (APP_ENV === 'staging' || APP_ENV === 'production') endpoint = `${AI_CHAT_URI}/api/v1/sofie/history`;

//   else endpoint = `${AI_CHAT_URI}/api/v1/sofie/history-dev`;

//   const response = await fetch(endpoint, {
//     method: 'POST',
//     headers: getHeaders(),
//     body: JSON.stringify(requestBody)
//   });

//   return response;
// }

/**
 * Retrieve a list of chat histories from the backend
 * @returns {Promise<Response>} response - object
 */
export async function getChatHistoriesAPI() {
  let endpoint = '';

  if (APP_ENV === 'staging' || APP_ENV === 'production') endpoint = `${AI_CHAT_URI}/api/v1/sofie/history`;

  else endpoint = `${AI_CHAT_URI}/api/v1/sofie/history-dev`;

  const response = await fetch(endpoint, {
    method: 'POST',
    headers: getHeaders()
  });

  return response;
}
