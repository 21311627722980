/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  List, ListItem, useTheme, useMediaQuery
} from '@mui/material';
import PropTypes from 'prop-types';
import ChatHistoryLink from '../ChatHistoryLink/ChatHistoryLink';
import { formatDateTimeStringForChatHistoryLink } from '../../../../helpers/SofieChat/utilities';
import { ChatProps } from '../../../../api/SofieChat/PropTypes';

const ChatHistoryList = (props) => {
  const {
    chats, toggleModal, setDeleteChatId, setDeleteChatTitle
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // or 'md', 'lg', depending on your requirements
  const items = chats.map((chat, index) => {
    let chatTitleString = '';
    if (isMobile) {
      chatTitleString = chat.topic.slice(0, 21);
    } else {
      chatTitleString = chat.topic.slice(0, 50);
    }

    const appendDots = chatTitleString.length >= 50 && !isMobile ? true : !!(chatTitleString.length >= 21 && isMobile);

    return (
      <ListItem key={index} disablePadding sx={{ alignItems: 'flex-start' }}>
        <ChatHistoryLink
          titleProp={appendDots ? `${chatTitleString}...` : chatTitleString}
          lastEditDate={formatDateTimeStringForChatHistoryLink(chat.end_time, isMobile)}
          color={index % 2 === 0 ? 'grey' : 'blue'}
          openModal={() => {
            toggleModal(true);
            setDeleteChatId(chat.chat_id);
            setDeleteChatTitle(`${chatTitleString}...`);
          }}
          isFavoritedProp={false}
          chatId={chat.chat_id}
        />
      </ListItem>
    );
  });
  return (

    <List>
      {items}
    </List>
  );
};

export default ChatHistoryList;

ChatHistoryList.propTypes = {
  chats: PropTypes.arrayOf(ChatProps).isRequired,
  toggleModal: PropTypes.func.isRequired,
  setDeleteChatId: PropTypes.func.isRequired,
  setDeleteChatTitle: PropTypes.func.isRequired
};
