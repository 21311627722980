import React from 'react';
import ChatBubbleOutlineRounded from '@mui/icons-material/ChatBubbleOutlineRounded';
import { Box, Typography } from '@mui/material';

const IntroMessage = () => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
  >
    <ChatBubbleOutlineRounded
      sx={{
        color: 'secondary.main',
        fontSize: '66px'
      }}
    />
    <Typography
      variant="h1"
      fontWeight="bold"
      textAlign="center"
      sx={{
        fontSize: '45px'
      }}
    >
      Ask Sofie
    </Typography>
  </Box>
);

export default IntroMessage;
