import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Typography, Link } from '@mui/material';
import { grey } from '@mui/material/colors';
import ArticleButtons from '../ArticleButtons/ArticleButtons';
import BlackCheckbox from '../../elements/BlackCheckbox/BlackCheckbox';
import { setChecked } from '../../actions/ask/askActions';

const Title = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'middle',
})(({ theme, disabled }) => ({
  fontSize: '1.25rem',
  letterSpacing: '-0.6px',
  fontWeight: 'bold',
  display: 'block',
  color: disabled ? grey[700] : theme.palette.darkGrey.main,
  textDecoration: 'none',
  overflowWrap: 'anywhere',
  hyphens: 'auto',
  '&:hover': {
    color: disabled ? grey[700] : theme.palette.darkGrey.main,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.875rem',
    letterSpacing: '-0.9px',
  },
}));

const Author = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'middle',
})(({ theme, disabled }) => ({
  fontSize: '0.8125rem',
  letterSpacing: '-0.39px',
  display: 'inline-block',
  color: disabled ? grey[500] : grey[600],
  [theme.breakpoints.up(428)]: {
    letterSpacing: '-0.45px',
    fontSize: '0.9375rem',
  },
}));

const SampleText = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'middle',
})(({ theme }) => ({
  fontSize: '1.0625rem',
  letterSpacing: '-0.51px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  lineClamp: '2',
  WebkitBoxOrient: 'vertical',
  wordBreak: 'break-word',
  [theme.breakpoints.up('lg')]: {
    fontSize: '1.3125rem',
    letterSpacing: '-0.63px',
  },
}));

const AskAnswer = (props) => {
  const {
    id,
    title,
    publisher,
    book,
    year,
    link,
    body,
    openShareArticles,
    dispatchSetChecked,
    checked,
    showCheckbox,
    topNotice,
    disabled,
    first,
    buttonsPosition,
    information,
    popupRequired,
    url
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const authorButtons = buttonsPosition === 'author';
  const titleButtons = buttonsPosition === 'title';

  const handleShareArticleClick = () => {
    const subtitle = `${book} - ${year}`;
    openShareArticles(title, subtitle, id, publisher, link);
  };

  const handleCheckboxClick = () => {
    let ids = [...checked];
    if (checked.includes(id)) {
      ids = ids.filter((item) => (
        item !== id
      ));
    } else {
      ids.push(id);
    }
    dispatchSetChecked(ids);
  };

  const isChecked = checked.includes(id);

  return (
    <Box
      sx={{
        position: 'relative',
        borderRadius: '10px',
        pl: showCheckbox ? { xs: 0, lg: 2.375, xl: 5 } : { xs: 1.75, lg: 5 },
        pr: showCheckbox ? { xs: 1.75, lg: 2 } : { xs: 1.75, lg: 2 },
        ml: { xs: 4, lg: 0 },
        mr: { xs: 3.25, lg: 0 },
        mt: first ? { xs: 2, lg: 0 } : { xs: 3.75, lg: 0 },
        pt: first ? { xs: 0, lg: 3.25, xl: 6 } : { xs: 0, lg: 3.25, xl: 6 },
        pb: { xs: 3, lg: 4.5, xl: 6 },
        display: 'flex',
        '&:hover': {
          backgroundColor: grey[200],
        },
      }}
    >
      {showCheckbox ? (
        <Box>
          <BlackCheckbox
            checked={isChecked}
            onChange={handleCheckboxClick}
            size={isMobile ? 'medium' : 'large'}
          />
        </Box>
      ) : (
        <></>
      )}
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <Box
          mb={{ xs: 1.125, lg: 2 }}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            {topNotice ? (
              <Typography sx={{ color: 'error.main', fontWeight: 'bold' }}>{topNotice}</Typography>
            ) : (
              <></>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mb: 1,
              }}
            >
              <Title
                component={RouterLink}
                to={url}
                disabled={disabled}
                target="_blank"
              >
                {title}
              </Title>
              {titleButtons && (
                <ArticleButtons
                  id={id}
                  handleShareArticleClick={handleShareArticleClick}
                  showPrint={false}
                  showShare={!(disabled || isMobile)}
                  information={information}
                  popupRequired={popupRequired}
                  title={title}
                  size={isMobile ? 2 : 2.18}
                />
              )}
            </Box>
            <Author disabled={disabled}>
              {`${book} - ${year} `}
              <Link
                href={link}
                underline="hover"
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  color: 'lightBlue.main',
                  '&:hover': {
                    color: 'lightBlue.main',
                  },
                }}
              >
                {publisher}
              </Link>
            </Author>
          </Box>
          <Box>
            {authorButtons && (
              <ArticleButtons
                id={id}
                handleShareArticleClick={handleShareArticleClick}
                showPrint={false}
                showShare={!disabled}
                popupRequired={popupRequired}
                title={title}
              />
            )}
          </Box>
        </Box>
        <SampleText>{disabled ? '' : `${body}...`}</SampleText>
      </Box>
    </Box>
  );
};

/**
 * Maps items from the redux store to apps props.
 * @param {object} state - des
 * @returns {{ user: *}} - maps user from redux to apps props
 */
function mapStateToProps(state) {
  return {
    checked: state.searchResults.checked,
  };
}

/**
 * Maps actions to component props.
 * @param {Dispatch} dispatch - allows action creators to work with redux.
 * @returns {{ setSelectedArticles: *}} - bound action creators
 */
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      dispatchSetChecked: setChecked,
    },
    dispatch
  );
}

AskAnswer.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  publisher: PropTypes.string.isRequired,
  book: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  openShareArticles: PropTypes.func.isRequired,
  dispatchSetChecked: PropTypes.func.isRequired,
  checked: PropTypes.arrayOf(PropTypes.string).isRequired,
  showCheckbox: PropTypes.bool,
  topNotice: PropTypes.string,
  first: PropTypes.bool,
  buttonsPosition: PropTypes.string,
  information: PropTypes.string,
  popupRequired: PropTypes.bool,
  disabled: PropTypes.bool,
};

AskAnswer.defaultProps = {
  showCheckbox: true,
  disabled: false,
  topNotice: '',
  first: false,
  buttonsPosition: 'author',
  information: '',
  popupRequired: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(AskAnswer);
