import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const SuggestionMessages = (props) => {
  const { suggestions, onClickSuggestion } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      mb="2%"
      mt="10%"
    >
      <Grid
        container
        spacing={2}
        sx={{
          [theme.breakpoints.up('sm')]: { width: '50%' },
          [theme.breakpoints.down('sm')]: { width: '90%' }
        }}
      >
        <Grid item xs={isMobile ? 12 : 6}>
          <Button
            fullWidth
            variant="contained"
            color="quaternary"
            sx={{
              height: '100%',
              boxShadow: 'none',
              color: '#002039',
              fontSize: { xs: '1.25rem' }
            }}
            onClick={() => { onClickSuggestion(suggestions[0]); }}
          >
            {suggestions[0]}
          </Button>
        </Grid>
        <Grid item xs={isMobile ? 12 : 6}>
          <Button
            fullWidth
            variant="contained"
            color="quaternary"
            sx={{
              height: '100%',
              color: '#002039',
              boxShadow: 'none',
              fontSize: { xs: '1.25rem' }
            }}
            onClick={() => { onClickSuggestion(suggestions[1]); }}
          >
            {suggestions[1]}
          </Button>
        </Grid>
        {!isMobile
        && (
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            color="quaternary"
            sx={{
              height: '100%',
              boxShadow: 'none',
              color: '#002039',
              fontSize: { xs: '1.25rem' }
            }}
            onClick={() => { onClickSuggestion(suggestions[2]); }}
          >
            {suggestions[2]}
          </Button>
        </Grid>
        )}
        {!isMobile
        && (
        <Grid item xs={6}>
          <Button
            fullWidth
            variant="contained"
            color="quaternary"
            sx={{
              height: '100%',
              boxShadow: 'none',
              color: '#002039',
              fontSize: { xs: '1.25rem' }
            }}
            onClick={() => { onClickSuggestion(suggestions[3]); }}
          >
            {suggestions[3]}
          </Button>
        </Grid>
        )}
      </Grid>
    </Box>
  );
};

SuggestionMessages.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.string),
  onClickSuggestion: PropTypes.func
};
SuggestionMessages.defaultProps = {
  suggestions: ['How is canine distemper diagnosed?', 'What is the latest recommended treatment for parvovirus in dogs?', 'How do I treat status epilepticus?', 'What treatments are recommended for feline atopy?'],
  onClickSuggestion: () => { }
};

export default SuggestionMessages;
